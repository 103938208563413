#create-modal .modal-dialog {
  position: absolute;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#create-modal .modal-content {
  width: 470px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#create-modal .modal-content {
background-color: #fefefe;
box-shadow: 0px 4px 23px 3px 
  rgba(99, 98, 98, 0.28);
  border-radius: 0;
  border: 0;
}

.info-body-wrapper {
  position: relative;
  width: 470px;
  height: 240px;
  overflow: hidden;
}

.info-body-list {
  position: absolute;
  min-width: 1600px;
  height: 100%;
}

.info-header {
  width: 100%;
  line-height: 60px;
  height: 60px;;
  text-align: right;
  background-color: #d5a256;
  padding: 0 23px; 
}
.info-header span {
  color: #fff;
  font-size: 16px
}
.info-title {
  float: left;
  font-size: 14px;
  color: #4d4a4a;
}

.fa-times {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  cursor: pointer;
}


.info-body {
  display: inline-block;
  padding: 27px 47px 38px 43px;
  width: 470px;
  /* text-align: center; */
}

.info-body div {
  font-size: 14px;
  color: #898888;
  display: inline-block;
  display: flex;
  line-height: 45px;
}
.info-body .creating {
  padding-left: 55px;
}
.info-body div span {
  line-height: 45px;
  color: #999;
  font-size: 14px;
}
.modal-row {
  margin-bottom: 28px;
}

.modal-row .modal-input {  
  flex: 1;  
  display: flex;
  flex-direction: column;
}
.modal-row .modal-input input {
  width: 100%;
  height: 45px;
  border: 1px solid #d5d5d5;
  font-size: 16px;
  color: #333;
  text-indent: 10px;

}
.modal-row .modal-input div{
  line-height: 1;
  color: #666;
}
.modal-row:last-child {
  /* margin-top: 40px; */
  text-align: center;
}

.modal-row button {
  width: 160px;
  height: 50px;
  font-size: 16px;   
  margin: 0 auto;
}
.modal-row button:hover {
  background: #d5a256;
} 
.black-btn {
  color: #fff;
  background-color: #000000;
  border: 0;
}

.row-header, .row-body {
  float: left;
}

.row-header {
  width: 80px;
  text-align: right;
}

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}
@media (max-width: 768px) {
  #preview .info-row>span, #preview .info-body div {
    line-height: 22px;
    font-size: 12px;
  }
  #preview .info-row>span {
    height: 30px;
  }
  #preview .info-body div:last-child{
    display: flex;
    flex-direction: column;
  }
  #preview .info-body div:last-child span {
    height: 22px;
  }
}
