.msgbackpage{
    background-color: #f2f2f2;
    min-height: 750px;
}

.msg-back{
    margin-top: 10.5vh;
    padding-top: 113px;
}

@media (min-width: 992px){
    .col-center-block {  
        float: none;  
        display: block;  
        margin-left: auto;  
        margin-right: auto;  
        padding: 0 ;
        width: 61.8%;
    } 
}




.msgbackpage .msgback-header{
    font-size: 24px;
    color: #262626;
    margin-bottom: 37px;
}

.msgbackpage .msgback-deatil {
	font-size: 14px;
    color: #4d4a4a;
    margin-bottom: 54px;
}



.msgbackpage .msgback-from label{
    margin-right: 7px;
    font-size: 16px;
    color: #262626;
    font-weight: normal;
   
}

.msgbackpage .msgback-from textarea, .msgback-from input{
    outline: none;
    width: calc( 100% - 87px);
    /* border: solid 1px #4d4a4a; */
   
}
@media (max-width : 768px){
    .msgbackpage .msgback-from textarea, .msgback-from input{
         border: solid 1px #4d4a4a;
    }
}


.msgbackpage .msgback-from .msgbacktxt{
    vertical-align: top;
    height: 123px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    resize: none;
}


.msgbackpage .msgback-from input{
    vertical-align: center;
    height: 40PX;
    padding-left: 12px ;
}

.msgbackpage .form-row {
    margin-bottom: 25px;
}


.msgbackpage .form-row .err{
    margin-top: 5px;
    margin-left: 110px;
    height: 15px;
}


.msgbackpage .upload-msg{
    width: 160px;
    height: 45px;
    background-color: #333333;
    font-size: 14px;
    color: #ffffff;
    float: none;  
    display: block;  
   margin: 80px auto 250px;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
}



 
.msgbackpage .upload {
    position: relative;
    width: 72px;
	height: 65px;
    background-color: #d6d8dc;
    line-height: 24px;
    text-align: center;
    padding: 0px 5px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    display: inline-block;
    cursor: pointer;
    vertical-align: top;
}


.msgbackpage .upload p {
    display: inline;
    font-size: 40px;
    color: rgba(38, 38, 38, 0.5);
    line-height: 65px;
}


.msgbackpage .upload form {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    filter: alpha(opacity=0);
}


.msgbackpage .upload form input {
    width: 100%;
    height: 100%;
    padding-left:0;
}


.msgbackpage .upload .err{
    position: absolute;
    top: 20%;
    width: 100%;
    margin-left: 0;
    left: 80px;
    width: 140px;
}


.msgbackpage .imgshow{
    margin-left: 86px;
    margin-top: 20px;
    /* width: 200px; */
    height: 100px;
}


.msgbackpage .imgshow img{
    height: 100%;
}


.msgbackpage .err span{
    color: #f00;
    font-size: 14px;
}



.msgbackpage .img-list{
    margin-left: 86px;
    margin-top: 20px;
    margin-bottom: 50px;
    min-height: 100px;
}


.msgbackpage .img-list .img{
    width: 100px;
    height: 100px;
    float: left;
    margin-right: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #ffffff;
} 


.msgbackpage .img:hover .delete{
    display: block;
}


.msgbackpage .img .delete{
    float: right;
    margin-right: 2px;
    font-size: 15px;
    cursor: pointer;
    display: none;
}


.msgbackpage .img span.num{
    margin: 40%;
    display: block;
}

/* 提示弹窗 s*/


.msgbackpage .tip-success{
    width: 360px;
}


.msgbackpage .tip-body{
    width: 100%;
    background-color: #ffffff;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}



.msgbackpage .tip-success .tip-success-img{
    margin-bottom: 19px;
}


.msgbackpage .tip-success .tip-success-txt{
    margin-bottom: 32px;
}


.msgbackpage .tip-success-txt h3{
    font-size: 20px;
    margin: 0;
}


.msgbackpage .tip-success-btn div{
    width: 120px;
    height: 34px;
    background-color: #efe1d9;
    text-align: center;
    line-height: 34px;
    cursor: pointer;
    font-size: 14px;
    color: #666666;
   
}

/* 提示弹窗 e*/
