html{

}
body {
    /* font-family: Helvetica, Tahoma, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei"; */
    font-family:  "Microsoft YaHei" !important;

    font-weight: 200;

}
input, textarea {
    outline: none !important;
}
.container {
    width: 100%;
}
a {
    color: #eee;
}

a:link {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.clearfix:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

#root {
 
    overflow-x: hidden;
    overflow-y: hidden;
}
#root .web {
   
    overflow-y: auto;
    overflow-x: hidden;
    background: #f9f9f9
}
#root .web .my {
   
    display: flex;
    flex-direction: column;
}
#root .web .my .my-content, #root .web .my .company-content, #root .web .my .my-body, #root .web .my > .content{
    flex: 1;
    display: flex;
    background: #f9f9f9;
}
#root .web .my .my-content .container-fluid, #root .web .my .my-content .container,#root .web .my .company-content .container, #root .web .my .my-body, #root .web .my > .content{
    flex: 1;
    overflow-y: hidden;
}
#root .web .my .my-body .site-list{
    overflow: hidden;
    flex: 1;
}
#nav .container {
    position: relative;
}
#nav .navbar-header {
    font-size: 14px;
    position: absolute;
    /* left: 0; */
    top: 50%;
    transform: translateY(-50%)
}
#nav .navbar-header .logo-wrapper {
    position: relative;
}

#nav .navbar-header .logo-wrapper img {
    width: 160px;
    height: 35px;
}

#nav.index-header {
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, .2);
    border: 0;
    background-color: #fff; 
    z-index: 99999;
}



.show {
    opacity: 1;
}

.header-menu {
    background-color: rgba(0, 0, 0, .5);
    float: left;
    margin-left: 15px;
}

.icon-bar {
    background: #fff;
}

.black-btn {
    color: #fff;
    background-color: #000000;
    border: 0;
}

.normal-btn {
    color: #4d4a4a;
}
#nav {
    /* position: absolute; */
    position: fixed;
    width: 100%;
    z-index: 99;
    border-radius: 0;
    height: 10.5%;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    margin-bottom: 0;
}
.phone-title-show {
    display: none;
}
.pc-title-show {
    display: block;
}
@media (max-width: 768px) {
    .index-header .collapsing, .index-header .in {
        float: right;
        width: 50%;
        background: #fff;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    #nav {
        height: 60px;
    }
    #nav .navbar-header {
        transform: translateY(0);
        position: relative;
    }
    #nav .navbar-toggle {
        position: absolute;
        z-index: 1;
        background: transparent;
        padding: 0;
        margin: 0;
        margin-left: 15px;
    }
    #nav .logo-left div{
        /* line-height: 44px; */
        font-size: 16px; 
    }
    .index-header .main {
        flex-direction: column;
    }

    .modal-backdrop {
        width: 100% !important;
    }

    .cta-text {
        left: 0;
        right: 0 !important;
        margin: 0 auto;
    }

    .cta-text .title1 {
        font-size: 20px;
        margin: 0 auto;
    }

    .cta-text .title2 {
        font-size: 16px !important;
        margin: 0 auto;
    }
    .phone-title-show {
        display: block;
    }
    .pc-title-show {
        display: none;
    }
}

.footer .nav-list .selected {
    color: #d5a256;
    border-bottom: 1px solid #d5a256;
}

#nav .navbar-right a.active {
    color: #333333;
}
/* 面包屑导航 */
#nav .breadcrumb{
    padding: 0 15px !important;
    margin-bottom: 0 !important;
    list-style: none;
    background-color: initial;
    border-radius: 4px;
}
#nav .breadcrumb>li+li:before{ 
    padding: 2 5px;
    color: rgb(235, 235, 235);
    /* content: none; */
    content:'\\';
    position: absolute;
    font-size: 16px;
    margin: -14px 0px 0 -20px;
    top: 50%;
}



.main a:hover {
    color: #333333;
}

#nav .main .personal {
    padding: 0;
}

.avatar-center {
    margin-left: 11px;
    width: 46px;
    height: 46px;
    background: #eee;
    border-radius: 50%;
    margin-right: 4px;
}

#button-row a {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.05);
    font-family: 'Helvetica65';
    border-radius: 5px;
    border: solid 2px #fff;
    cursor: pointer;
    outline: none;
    font-weight: normal;
    padding: 0px;
    display: inline-block;
    text-align: center;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 146px;
    height: 36px;
    font-size: 20px;
    border-radius: 4px;
    margin: 24px 0px;
    line-height: 36px;

}

#button-row a:hover {

    background-color: rgba(255, 255, 255, 0.15);
}

#nav a {
    color: #4d4a4a;

}

#nav a:hover {
    color: #0f0f0f;
}

#nav .nav > li {
    margin: 0 15px;
}

#nav .nav > li > a {
    display: block;
    padding: 8px 10px;
    font-size: 16px;
    line-height: 1;
    border-bottom: 2px solid transparent;

}
#nav .nav > li:hover>a {
    color: #d5a256;
}

/* #nav .nav > li.right2 {
    margin-left: 35px;
    margin-right: 0;
} */
/* #nav .nav > li.right2:before{
    display: none;
} */

#nav .nav > li.right2 a {
    /* padding: 0 12px 0 10px; */
    vertical-align: middle;
}
/* 个人中心 */
#nav .nav > li.right2 #droppdown-box{
    cursor: pointer;
}

#nav .nav > li.right2 #droppdown-box .dropdown-menu{
    width: 150px;
    margin-top: 20px;
    border:0;
    border-radius: 0;
    padding: 0;
}

#nav .nav > li.right2 #droppdown-box .dropdown-menu{
    width: 150px;
    margin-top: 20px;
    height: auto;
}
#nav .nav > li.right2 #droppdown-box .dropdown-menu .nav-link{
    height: 40px;
    line-height: 40px;
}

#nav .nav > li.right2 #droppdown-box .dropdown-menu .nav-link a{
    text-align: left;
    line-height: 40px;
    color: #999;
    width: 85%;
    margin: 0 auto;
    padding: 0;
    border-bottom: 1px solid #eee;
}
#nav .nav > li.right2 #droppdown-box .dropdown-menu .nav-link a> span {
    display: inline-block;
    width: 65%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
#nav .nav > li.right2 #droppdown-box .dropdown-menu .nav-link:last-child a {
    border: 0;
}
#nav .nav > li.right2 #droppdown-box .dropdown-menu .nav-link:hover{
    background: #d5a256;
}
#nav .nav > li.right2 #droppdown-box .dropdown-menu .nav-link:first-child:hover{
    background: #fff;
    cursor: auto;
}
#nav .nav > li.right2 #droppdown-box .dropdown-menu .nav-link:first-child a {
    color: #333;
}
#nav .nav > li.right2 #droppdown-box .dropdown-menu .nav-link:first-child:hover a{
    color: #333;
}
#nav .nav > li.right2 #droppdown-box .dropdown-menu .nav-link:hover a{
    color: #ffffff;
}

#nav .nav > li.right4 {
    margin: 0;
}
#nav .nav > li.right4:before{
    display: none;
}

#nav .nav > li.right4 a {
    padding: 0 0 0 30px;
    border-left: 1px #e4bb5d solid;
    margin-right: 15px;
    margin-left: 0px;
}


#nav .nav > li.right4 a {
    /* margin-left: -7px; */
    margin: 0;
}

/* #nav .nav > li.right2.active a, #nav .nav > li.right4.active a {
    border-bottom: none;
} */

#nav .main {
    display: flex;
    justify-content: center;
    align-items: center;
}

#nav .navbar-text.login-status {
    margin-top: 7px;
    margin-bottom: 0px;
}

#nav .navbar-text.signup {
    margin: 16px 0 0 140px;
}

#nav .navbar-text a {
    font-size: 16px;
    margin-right: 33px;
}

/* #nav .logo-left a {
    display: inline-block;
    padding: 0;
} */

#nav .navbar-brand img {

    margin-top: -12px;
    height: 50px;
}

#nav .logo-title {
    float: left;
    margin-left: 17px;
    line-height: 22px;
    font-size: 14px;
    font-family: Helvetica;
    color: #4d4a4a;
}

#nav .navbar-right a {
    color: #666;
    font-size: 16px;
}

#nav.home .navbar-brand img {

    margin-top: -25px;
    height: 50px;
}

#nav a:hover, #nav a.active {

    color: #333333;
    background-color: transparent;
}

#nav li.active a {
    color: #d5a256;
    /* background-color: transparent; */
}

#nav .login-status .fa-user-md {

    background: #ffffff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    line-height: 40px;
    text-align: center;
    color: #000;
    margin-right: 10px;
}

/*页尾*/
/* NEW FOOTER */
/* foot s */

.footer {
    background-color: #fff;
    z-index: 999;
    padding: 30px 0;
    padding-bottom: 0;
}
.footer .webTips{
    display: none;
}
.my .footer {
    padding-bottom: 0;
}
.my .footer .bot-top, .my .footer .bot-right .share{
    display: none;
}

.bot-top {
    text-align: center;
}
.bot-top .line{
    border-color: #d2d2d2;
}

.bot-top img {
    width: 100px;
    height: 90px;
}


.bot-right .share {
    /* display: flex;
    justify-content: flex-end;
    margin-bottom: 22px;
    position: relative; */
    text-align: center;
    margin-bottom: 30px;
}

.bot-right .share a {
    cursor: pointer;
}


.bot-right .share img {
    margin-left: 32px;

}

.bot-right .nav-list {
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
}

.bot-right .nav-list a {
    padding: 0 10px;
    cursor: pointer;
    color: #666666;
    font-size: 14px;
    line-height: 14px;
}
.bot-right .nav-list a:hover {
    color: #d5a256;
    border-bottom: 1px solid #d5a256;
}

.bot-right .nav-list a:last-child {
    padding-right: 0;
    border-right: none;
}

.bot-right .openwechat{
    position: relative;
}

.openwechat:hover .wechat {
    display: block;
}

.openwechat .wechat {
    width: 300px;
    height: 157px;
    background-color: #ffffff;
    padding: 10px 12px 17px 12px;
    position: absolute;
    display: none;
    top: -185px;
    left: -107px;
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.11);
}

.Wechat-blog {
    height: 147px;
}

.Wechat-blog .blog-img {
    width: 130px;
    height: 130px;
    float: left;
}

.Wechat-blog .blog-img img {
    display: block;
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-bottom: 14px;
}

.blog-deatil {
    float: left;
    width: 126px;
    height: 100%;
    text-align: left;
}

.blog-deatil .title {
    font-size: 16px;
    color: #262626;
    line-height: 1;
    margin-top: 9px;
    margin-bottom: 14px;
}

.blog-deatil .deatil-1 {
    font-size: 14px;
    color: #333333;
    line-height: 17px;
    margin-bottom: 11px;
}

.blog-deatil .deatil-2 {
    font-size: 14px;
    line-height: 17px;
    color: #666666;
}

.wechat .sanjiaoxing {
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent;
    margin: 0 auto;
    border-left-width: 8px;
    border-right-width: 8px;
    margin-left: 133px;
}

footer .declare {
    font-size: 14px;
     text-align: center;
     margin-bottom: 30px;
}


footer .declare span {
    /* font-size: 12px; */
    display: inline-block;
    line-height: 12px;
    color: #999999;
}
.pc-sign-login-btn {
    display: block;
}
.phone-sign-login-btn, .nologin-phone-user{
    display: none;
}
@media (max-width : 768px){
    .web .footer {
        padding: 0;
        height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .web .footer .declare{
        font-size: 12px;
        margin-bottom: 0;
    }
     .footer .webTips{
        display: block;
    }
    .wechat .sanjiaoxing{
        margin-left: 110px;
    }
    .pc-sign-login-btn {
        display: none;
    }
    .phone-sign-login-btn, .nologin-phone-user {
        display: block;
    }
    .footer .bot-top{
        display: none;
    }
    .nav.navbar-nav.navbar-right.main li.active{
        background: #d5a256;
    }
    .footer .bot-right .nav-list{
        display: none;
    }
    .footer .bot-right .share{
        display: none;
    }
    /* #nav .main{display: block} */
    #nav .nav > li > a {
        text-align: left;
        line-height: 29px;
    }
    #nav .nav > li > a.left-link{
        color: #898989;
    }
    #nav .nav > li.active a{
        color: #333;
    }
    #nav .nav > li .personal-center{
        color: rgb(213, 162, 86);
        width: 141px;
        height: 33px;
        line-height: 33px;
        border: 1px solid #d5a256;
        border-radius: 17px;
        margin: 0 auto;
        cursor: pointer;
    }
    #nav .nav > li .personal-center a {
        color: #d5a256;
    }
}




@media (max-width: 654px) {
    
    .foot-container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

    }
    #nav .navbar-header .logo-wrapper img{
        width: 80px;
        height: auto;
    }
    .foot-container .logo{
        margin-bottom: 30px;
    }

    .bot-right{
        display: flex;
        align-items: center;
        flex-direction: column;
    }


    .bot-right .nav-list{
        width: 100vw;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .bot-right .share{
        /* display: flex;
        justify-content: space-around;
        margin-bottom: 22px;
        position: relative; */
        width: 200px;
      
    }

    .bot-right .share img{
        margin-left: 0;
    }


    .declare span{
        text-align: center;
    }

    .bot-right .nav-list a{
        margin-bottom: 10px;
    }

    .footer .declare span{
        text-align: center;
        padding-bottom: 8px;
        margin-left: 0;
    }
}

/* foot.js e */



.icon {
    color: #666;
    font-size: 12px;
}
#nav .user-mobile-img-content {
    margin-top: 35px;
    display: inline-block;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
}
.btn-green-line {
    border: 1px solid #00c4d8;
    border-radius: 2px;
    color: #00d9ef;
    font-size: 12px;
}

@media (max-width: 1400px) {
    #nav .navbar-text.user-tab {
        margin-left: -15px;
        margin-right: -15px;
    }

    .user-tab a {
        margin: 0 !important;
    }

    a {
        text-align: center;
    }

    .active {
        border-bottom: 0 !important;
    }
}

/* @media (max-width: 1000px) {
    #nav a {
        padding: 10px 10px!important;
    }
} */

@media screen and (max-width: 768px) {
    .user-tab a {
        display: block;
    }

    #nav .nav > li.right4 a {
        padding: 8px 10px;
        border: 0;
    }
    #nav .icon-mobile-bar {
        width: 24px;
        height: 16px;
        background: url(http://lingdaima.dotlinkface.com/mobile-top-btn.png);
        background-size: cover;
    }
    .index-header .navbar-collapse {
        /* position: absolute; */
        position: fixed;
        width: 100%;
        overflow-y:auto;
        left: 15px;
        top: 0;
        height: 100%;
        background-color: transparent;
        padding: 0;
    }
     .index-header .navbar-collapse .navbar-mobile-collapse {
        height: 100%;
        width: 55%;
        background: #333;
        transition: all 0.3s ease-in-out;
        margin-left: -100%;
    }
    .index-header .navbar-collapse .navbar-mobile-collapse ul {
        margin: 0;
        
    }
    #nav .nav > li.right4, #nav .nav > li.right2, #nav .nav > li {
        padding: 0 15px;
        margin: 0;
    }
    #nav .nav > li a{
        color: #fff;
        font-size: 14px;
    }
}

@media screen and (max-device-width: 480px) {
    .avatar-center {
        display: none;
    }

    .cta-text .title1 {
        font-size: 16px;
        width: auto !important;
    }

    .cta-text .title2 {
        font-size: 12px !important;
        width: auto !important;
    }
}

@media (max-width: 992px) {
    #nav .logo-left {
        margin-left: 15px;
        text-align: center;
    }

}

/* @media (max-width: 768px) {
    .container {
        max-width: 375px !important;
    } 
} */

/* @media (min-width:1300px) and (max-width: 1600px){
    .container {
        width: 1200px !important;
    } 
} */

@media (min-width: 1600px) {
    .container {
        width: 1400px;
    } 
}
