
.index-header.signup {
  background-color: #fff;
}

.sign-up .form-row {
  margin-top: 0;
}


.signup-wrapper {
  background: url('http://lingdaima.dotlinkface.com/login-bg.png') no-repeat center;
  background-size: cover;
  height: 75em;
  position: relative;
  margin-top: 10.5vh;
}

.signup-page.container {
  /* width: 100%; */
  margin-top: 10.5vh;
  padding: 0;
}

.signup-wrapper .sign-up {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
}
.signup-wrapper .signup-left-image {
  display: inline-block;
}

.sign-up .signup-box {
  background-color: #fff;
  height: 100%;
  padding: 46px 0;
  width: 30vw;
  max-width: 500px;
  min-width: 370px;
  max-height: 700px;
  height: 40vw;
  min-height: 600px;
  float: right;
}

.signup-type {
  text-align: center;
}

.signup-type a {
  font-size: 18px;
  color: #333;
  font-weight: normal;
  /* margin-right: 121px; */
}

.signup-type .active-signup {
  color: #333;
}

.signup-form {
  margin-top: 30px;
  padding: 0 15%;
}

.signup-form .form-row {
  padding: 0;
  margin-bottom: 15px;
}
.error-info{
  height:46px;
}

.signup-form .form-row-error {
  height: 20px;
  margin-bottom:5px;
  display:none;
  /* padding-left: 17%; */
}

.signup-form .form-row .signup-icon {
  float: left;
  margin-right: 10px;
}

.signup-form .form-row input {
  float: left;
  width: 80%;
  height: 33px;
  border: 0;
  outline: none;
  /* border-bottom: 1px solid #d2d2d2; */
  background: rgba(0, 0, 0, 0);
}
.signup-form .login-button{
  text-align: right;
}
.signup-form .login-button a{
  color: #d5a256;
  font-size: 14px;
} 

#validate {
  width: 50%;
  border: 0;
}

.signup-form .form-row-content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  border-bottom:1px solid #999999;
  margin-bottom:20px;
}

.signup-form .signup-icon .icon {
  font-size: 24px !important;
  margin-left: 0;
  display: block;
}

.signup-form .form-row .designer-name {
  background: url("http://lingdaima.dotlinkface.com/designer-name.png") no-repeat;
  width: 21px;
  height: 35px;
  margin-left: 4px;
  margin-right: 25px;
}

.signup-form .form-row .validate-btn {
  width: 120px;
	height: 28px;
  background: #ddd;
  border: 0;
}

.signup-form .form-row .signup-agree {
  font-size: 14px;
  color: #999999;
  padding-left: 30px;
}
.rua-signup-radio{
  height:16px;
  width:16px;
  border-radius:50%;
  border:1px solid #d5a256;
  position: absolute;
  top: 2px;
  left:0;
}
.add-rua-signup-radio{
  background:url('https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/test-gouxuan.png') 0px -1px no-repeat;
}
.signup-form .signup-relative{
  position: relative;
}


.signup-form .form-row .signup-btn {
  text-align: center;
}

.signup-form .form-row .signup-btn button {
  width: 100%;
	height: 46px;
  background-color: #d5a256;
  color: #fff;
  font-size: 17px;
  border: 0;
}

.signup-form .err-msg {
  font-size: 15px;
  height: 40px;
  padding-left: 18.5%;
  line-height:40px;
  background:#f0dfc4;
  border:1px solid #d5a256;
  color:#666666;
  display:block;
  position: relative;
}
.signup-form .err-msg:before{
  content: "";
  position: absolute;
  height: 25px;
  width: 25px;
  left: 13px;
  top: 7px;
  background: url(https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/error-tip-icon.png) no-repeat;
  background-size: cover;
}

.signup-form .tester-msg {
  color: #337ab7;
  text-decoration: underline;
}

.signup-form .form-row .hide {
  display: none;
}

.signup-form .form-row .code-button {
  width: 120px;
  height: 28px;
  background: #ddd;
  border: 0;
}



.signup-form .code-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 80%;
  text-align: right;
  border-bottom: 1px solid #d2d2d2;
}


.signup-body-wrap #create-modal .modal-content {
  width: 300px;
  height: 200px;
}

.signup-body-wrap #create-modal .modal-content  .modal-row {
  margin-top: 28px;
  margin-bottom: 0;
  text-align: center;
}

.signup-body-wrap #create-modal .modal-content  .modal-row:last-child {
  padding: 0;
} 

.signup-body-wrap #create-modal .modal-content .info-body-wrapper {
  width: 100%;
  height: auto;
}

.signup-form .form-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: 0 auto;
}

.signup-form .form-bottom .lines {
  width: 30%;
}

.signup-page .form-row .lines {
  border-bottom: 1px solid #d2d2d2;
}

.signup-page .form-row .bottom-link a {
  line-height: 33px;
  font-size: 14px;
  color: #898888;
}
.signup-form .signup-icon .icon{
  color:#333;
}

@media (max-width:1440px){
  .sign-up .signup-left-image{
    width: 54.7%;
  }
  .sign-up .signup-left-image img{
    width: 100%;
  }
  .sign-up .signup-box{
    /* width: 45%; */
    margin-right: 30px;
  }
}
@media (max-width: 992px) {
  .signup-icon {
    margin-right: 5px!important;
    margin-bottom:0;
  }

  .email-confirm button {
    width: 100%;
  }
  .btn-wrapper {
    padding: 20px;
  }
}

@media screen and (max-width: 600px) {

  .signup-form {
    padding: 0 5px;
  }

  .sign-up .signup-box {
    padding: 20px 0;
  }

}
@media screen and (max-width: 768px){
  .sign-up .signup-box{
    margin-left:0;
    height:472px;
    width: 86%;
    box-sizing: border-box;
    margin: 0 auto;
    min-width: auto;
    min-height: auto;
  }
  .signup-form .rua-signup-radio{
    left:40px;
  }
  .signup-form .form-row-content {
    margin-bottom: 20px;
  }
  .signup-form .err-msg{
    height: 0;
    padding-left: 0;
    line-height: 30px;
    background: #f0dfc4;
    border: none;
    display: block;
  }
  .signup-wrapper{
    background-position: 70%;
  }
  .signup-wrapper .sign-up{
    padding:0;
  }
  .signup-wrapper .signup-left-image{
    display:none;
  }
  .signup-page.container{
    margin-top: 50px;
  }
  .phone-signup-box{
    display:none;
  }
  .signup-box .signup-form .signup-icon .iconfont{
    width:24px;
    height:25px;
    margin-top:0;
  }
  .signup-box .form-row-error{
    height:30px;
    background:#f0e0c4;
    border:1px solid #d8a958;
    line-height:30px;
  }
  .signup-icon{
    padding-top:5px;
  }
  .sign-resigner{
    margin-top:31px !important;
  }
  .signup-form .login-button{
    margin-bottom:10px !important;
  }
  .signup-box .form-row-error,.error-info{
    width:auto;
    height:30px;
  }
  .signup-box .err-msg{
    font-size:11px;
    color:#666666;
    position: relative;
    /* display: none; */
    width: 100%;
  }

  .signup-box .form-row-error{
    padding-left:40px;
    /* margin:0 43px; */
    display:none;
    margin-bottom:0px;
  }
  .signup-box .err-msg:nth-of-type(1):before{
    content:"";
    position: absolute;
    height:15px;
    width:15px;
    left:-24px;
    top:7px;
    background:url('https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/error-tip-icon.png') no-repeat;
    background-size:cover;
  }
  .signup-form .form-row-content{
    border-bottom:1px solid #999999;
  }
  .signup-form .form-row{
    padding:0 35px;
    margin-bottom:10px;
  }
  .signup-form .form-row input{
    border-bottom:none;
  }
  .signup-form .signup-icon .icon{
    font-size:18px !important;
  }

  
}

@media screen and (max-width: 1240px) {
  .signup-wrapper .sign-up {
    left: 0;
    right: 0;
    margin-top: 50px;
  }
}


@media screen and (max-device-width: 480px) {
  .signup-wrapper {
    height: 720px;
    margin-top: 0;
  }
  
  .email-confirm button {
    width: 100%;
  }

  .btn-wrapper {
    padding: 20px;
  }


  .signup-page {
    width: 100%;
  }

  .signup-page .form-row .bottom-link a {
    font-size: 12px;
  }
}