.info-body {
  vertical-align: middle;
}

.code-btn {
  background-color: #e1e1e1;
  border: 0;
  color: #4d4a4a;
}

.hide {
  display: none;
}

.resend {
  color: #c6c6c6;
  margin-left: 24px;
}

.content{
  position: relative;
  overflow: hidden;
}

.item-header {
  line-height: 24px;
}

.code-row .row-header {
  line-height: 30px;
}

.phone-val {
  margin-right: 20px;
}

.modal-row .row-body input {
  width: 100%;
  height: 45px;
  border: 1px solid #d2d2d2;
}
.modal-row.success-row {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-row.success-row p.icon{
  font-size: 35px;
}
.error-tips {
  color: red;
  margin-left:10px;
}
.modal-row button {
  border: 0;
}
.modal-row button.code-btn {
  width: 160px;
}
.modal-row .error-tips {
  display: inline-block;
  white-space: nowrap;
  height: 28px;
  color: #f00;
  margin-left: 10px;
}

.row-header {
  text-align: left;
}

.tel-title {
  line-height: 26px;
}

.err-row {
  margin-bottom: 5px;
}

code-error {
  font-size: 12px;
  color: #f00;
}

.success-row {
  padding: 0;
}