.casechannel {
    width: 100%;
    height: auto;
    background-color: #f9f9f9;
    margin-top: 10.5vh;
}
.casechannel .container-fluid {
    overflow-x: hidden;
}
.casechannel .txtcenter {
    text-align: center;
    color: #333;
}
.casechannel .txt-en{
    width: 35%;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    color: #bbb;
    vertical-align: middle;
    position: relative;
}

.casechannel .txt-en span {
    display: inline-block;
    border: 1px solid #e5e5e5;
    width: 30%;
    line-height: 26px;
}
.casechannel .txt-en span i {
    position: absolute;
    width: 7px;
    height: 7px;
    background: #e5e5e5;
    right: -1px;
    border-radius: 100%;
    top: -4px;
}

.casechannel .txt-en span.txt-left {
    position: absolute;
    left: 0px;
    top: 50%;
}
.casechannel .txt-en span.txt-right {
    position: absolute;
    right: 0px;
    top: 50%;
}
.casechannel .txt-en span.txt-right i {
    left: -1px;
    border-radius: 100%;
}
.casechannel .txt-en .txt-center {
    border: 0;
}
.casechannel #nav.index-header {
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, .2);
}

.case-channel {
    margin-top: 63.3px;
    overflow: hidden;
}

.case-channel .case-list {
    margin: 50px 0;
    width: 100%;
}
.case-channel .case-list .case-item {
    width: 90%;
    margin-left: 10%;
    position: relative;
}
.case-channel .case-list .case-item .case-address {
    position: absolute;
    left: -15%;
    width: 13.8%;
    color: #666;
    line-height: 32px;
    border-bottom: 1px solid #e5e5e5;
    text-align: right;
}
.case-channel .case-list .case-item:first-child{
    margin-top: 40px;
}
.case-channel .case-list .case-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.case-channel .case-list .item-left {
    width: 40%;
    max-height: 300px;
    background-color: #ffffff;
    padding: 0px!important;
    position: absolute;
    top:0;
    Z-index: 2;
    overflow: hidden;
}

.case-channel .case-list .item-left img {
    width: 100%;
    height: 100%;
    transition: all ease-in-out 0.5s;
}

.case-channel .case-list .case-view {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s ease-in-out;
   
}

.case-channel .case-list .item-left:hover .case-view{
  opacity: 1;
}
.case-channel .case-list .item-left:hover  img{
  transform: scale(1.1)
}
.case-channel .case-list .case-view a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.case-channel .case-list .item-right {
    width: 100%;
    height: 300px;
    padding-left: 45%;
    
}

.case-channel .case-list .item-right .case-name {
    font-size: 20px;
    color: #333333;
    line-height: 1;
    font-size: 22px;
    font-weight: bolder;
    position: relative;
    line-height: 50px;
}
.case-channel .case-list .item-right .case-name-border {
    width: 20px;
    border-top: 1px solid #D5A256;
    position: absolute;
    bottom: 0px;
    left: 0;
}
.case-channel .case-address-phone {
    display: none;
}
.case-channel .case-list .item-right .case-urllink {
    display: flex;
    width: 100%;
    align-items: center;   
    position: absolute; 
    bottom: 0;
}

.case-channel .case-list .case-urllink span:nth-child(2) {
    font-size: 16ox;
    line-height: 1;
    color: #D5A256;
    margin-left: 14px; 
}

.case-channel .case-list .case-urllink a {
    font-size: 14px;
    color: #D5A256;
    margin-left: 6px;
}

.case-channel .case-list .item-right .case-address span:first-child {
    font-size: 18px;
    line-height: 1;
    color: #888888
}

.case-channel .case-list .item-right .case-address span:last-child {
    font-size: 16px;
    line-height: 1;
    color: #666666
}

.case-channel .case-list .item-right .item-right-2 .item-right-txt {
    font-size: 16px;
    line-height: 26px;
    color: #666;
    border-radius: 5px;
    float: left;
    margin-top: 20px;
}

.case-channel .case-list .item-right .item-right-3 {
    margin-top: 30px;
    display: flex;
    align-items: center;
    /* margin-bottom: 40px; */
}

.norrw{
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: #e4e4e4 transparent transparent transparent;
    float:left;
}

.case-channel .case-list .item-right .item-right-3.margintop12 {
    margin-top: 12px;
}

.case-channel .case-list .item-right .item-right-3 img {
    margin-right: 10px;
}

.case-position {
    font-size: 14px;
    color:#666666;
    margin: 0 5px;
}
.case-channel .goto-onetoone {
    display: none;
}

.case-channel .dashed-line {
    width: 100%;
     display: flex; 
     flex-direction: column; 
     height: 1px; 
     position: relative;
}

.case-channel .dashed-line div {
    border-top: 1px dashed #e5e5e5;
    width: 50%;
    position: absolute; 
    right: 0
}
/* @media () */

@media screen and (max-width: 768px) {
    .casechannel {
        margin-top: 0px;
    }
    .case-channel .case-list {
        margin-top: 0;
    }
    .casechannel .case-address {
        display: none;
    }
    .case-channel .case-address-phone {
        display: block;
        font-size: 12px;
        color: #999;
        float: right;
        line-height: 40px;
        margin-right: 8%;
    }
    .case-channel .dashed-line {
        display: none
    }
    .casechannel .txtcenter, .casechannel .txt-en {
        display: none;
    }    
    .case-channel .case-list .case-item {
        margin-bottom: 10px;
        flex-direction: column;
        width: 100%;
        margin-left: 0;
        background: #fff;
        border: 1px solid  #e5e5e5;
        padding: 0!important;
    }
    .case-channel .case-list .case-item:first-child {
        margin-top: 10px;
    }
    .case-channel .case-list .item-left,
    .case-channel .case-list .item-right {
        padding-left:0;
        width: 100%;
        position: relative;
        
    }
    .case-channel .case-list .item-left {
        max-height: 200px;
    }
    .case-channel .case-list .item-right .case-name {
        margin-top: 45px;
        width: 100%;              
    }
    .case-channel .case-list .item-right .case-name > span:first-child {
        color: #000;
        font-weight: 700;
        font-size: 14px; 
    }
    .case-channel .case-list .item-right .case-urllink,
    .case-channel .case-list .item-right .case-address {
        margin-top: 28px;
    }
    .case-channel .case-list .item-right .case-urllink {
        margin-left: 11px;
        border-bottom: 0;
        padding-bottom: 30px;

    }
    .case-channel .case-list .item-right .case-urllink>a {
        transition: all 0.5s ease-in-out;
    }
    .case-channel .case-list .item-right .case-urllink:hover .case-channel .case-list .item-right .case-urllink > a {
        margin-left: 20px;
    }
    .case-channel .case-list .item-right .item-right-3 {
        margin-bottom: 0;
        margin:15px 11px 0;
    }
    .case-channel .case-list .item-right .case-name {
        margin-top: 0;
        line-height: 40px;
        margin-left: 11px;
    }
    .case-channel .case-list .item-right .item-right-2 .item-right-txt {
        padding: 0 11px;
        font-size: 12px;
        line-height: 16px;
    }
    .case-channel .goto-onetoone{
        
        border-radius: 5px;
        display: inline-block;
        justify-content: center;
        align-items: center;
        margin: 20px auto 20px;
        width: 100%;
        
    }
    .goto-onetoone span.ontoone-btn-txt{
        font-size: 12px;
        color: #333;
        position: relative;
        padding: 8px;
    }
    .goto-onetoone span.border-span {
        position: absolute;
        width: 8px;
        height: 8px;     
    }
    .goto-onetoone span.left-top {
        left: 0;
        top: 0;
        border-left: 1px solid #333;
        border-top: 1px solid #333;
    }
    .goto-onetoone span.right-top {
        right: 0;
        top: 0;
        border-right: 1px solid #333;
        border-top: 1px solid #333;
    }
    .goto-onetoone span.left-bottom {
        left: 0;
        bottom: 0;
        border-left: 1px solid #333;
        border-bottom: 1px solid #333;
    }
    .goto-onetoone span.right-bottom {
        right: 0;
        bottom: 0;
        border-right: 1px solid #333;
        border-bottom: 1px solid #333;
    }
    .case-channel .item-right-1, .case-channel .item-right {
        padding: 0;
    }
}