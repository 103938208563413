.index-header.login {
  background-color: #fff;
}

.login-wrapper {
  background: url('http://lingdaima.dotlinkface.com/login-bg.png') no-repeat center;
  background-size: cover;
  height: 75em;
  position: relative;
  margin-top: 10.5vh;
}
.errInfoHei{
  height:30px;
}
.login-page.container{
  /* width: 1440px; */
  padding: 0;
}

.login-box-wrapper {
  /* text-align: center; */
  padding-top: 8.3em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.login-box-wrapper .login-left-image{
  display:inline-block;
}
.login-box-wrapper .login-left-image img{
  width: 100%;
}

.login-box-wrapper .login-box {
  display: inline-block;
  background-color: #fff;
  height: 100%;
  padding: 80px 0;
  width: 30vw;
  max-width: 500px;
  min-width: 350px;
  height: 45vw;
  min-height: 500px;
  max-height: 700px;
  float: right;
}

.login-page .login-type {
  border-bottom: 1px solid #c6c6c6;
  width: 446px;
  padding-bottom: 22px;
}

.login-page .login-type a {
  font-size: 17px;
  color: #898888;
  margin-right: 121px;
}

.login-page .login-type .active-login {
  color: #ed8aa2;
}

.login-page .login-form {
  padding: 0 15%;
}

.login-page .form-row {
  margin-bottom: 42px;
  margin-top: 0;
}

.login-form .forget-pwd-row {
  text-align: right;
  margin-top: 40px !important;
  margin-bottom: 26px;
  overflow: hidden;
  font-size: 14px;
}
.login-form .forget-pwd-row .forget-passward{
  float: left;
}

.login-form .forget-pwd-row .sign-up-button a{
  color: #d5a256;
}
.login-form .forget-pwd-row .forget-passward a{
  color: #d5a256;
}

.login-form .form-header-img {
  background: url('http://lingdaima.dotlinkface.com/login-avatar.png') no-repeat center;
  background-size: cover;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.login-form .form-row-content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  border-bottom:1px solid #999999;
}
.login-form .login-btn{
  border-bottom:none;
}

.login-form .form-row-error {
  padding-left: 18.5%;
  line-height:40px;
  background:#f0dfc4;
  border:1px solid #d5a256;
  color:#666666;
}

.login-page .form-row .login-icon {
  float: left;
  margin-right: 20px;
}

.login-page .form-row input {
  float: left;
  width: 90%;
  height: 33px;
  border: 0;
  outline: none;
  /* border-bottom: 1px solid #c6c6c6; */
  background: rgba(0, 0, 0, 0);
}

.login-page .form-row .login-btn button {
  /* width: 119px; */
  width: 100%;
	height: 46px;
  background-color: #d5a256;
  color: #fff;
  font-size: 17px;
  border: 0;
}

.login-form .login-icon .icon {
  font-size: 24px !important;
  margin-left: 0;
  display: block;
}

.login-page .err-msg {
  font-size: 16px;
  color: #666666;
  width: 338px;
  /* background-color: #d5a256; */
  position: relative;
}
.login-page .err-msg:nth-of-type(1):before{
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  left: -61px;
  top: -4px;
  background: url(https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/error-tip-icon.png) no-repeat;
  background-size: cover;
}

.login-page .form-row .hide {
  display: none;
}

.login-page .form-row .code-button {
  width: 122px;
  height: 31px;
  font-size: 12px;
  border: solid 1px #c6c6c6;
  color: #c6c6c6;
}


.login-form .form-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: 0 auto;
}

.login-form .form-bottom .lines {
  width: 30%;
}

.login-page .form-row .lines {
  border-bottom: 1px solid #d2d2d2;
}

.login-page .form-row .bottom-link a {
  line-height: 33px;
  font-size: 14px;
  color: #898888;
}
.login-form .login-icon .icon{
  color:#333;
}


/* .form-row .bottom-link:first-child {
  margin: 0 20% 0 5%;
}

.form-row .bottom-link:last-child {
  margin: 0 5% 0 20%;
  text-align: right;
} */

@media screen and (max-width: 600px) {
  .login-wrapper{
    margin-top:80px;
  }
  .login-page .login-box-wrapper .login-box .login-form>div{
    width:73.3%;
    margin:0 auto;
    height:39px;
    margin-bottom: 20px;
  }
  .login-form .form-row-content{
    position: relative;
  }
  .login-form .form-row-content .login-icon{
    width:17px;
    height:24px;
  }
  .login-form .form-row-content>label{
    width:17px;
    height:24px;
  }
  .login-form .form-row-error{
    height:30px;
    background:#f0e0c4;
    border:1px solid #d8a958;
    line-height:30px;
    font-size:11px;
  }
  .login-page .err-msg{
    font-size:11px;
    color:#666666;
    position: relative;
    /* display: none; */
    width: 100%;
  }
  .login-page .error-info{
    height:30px;
    width:100%;
  }
  .login-page .err-msg:nth-of-type(1):before{
    content:"";
    position: absolute;
    height:15px;
    width:15px;
    left:-50px;
    top:0px;
    background:url('https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/error-tip-icon.png') no-repeat;
    background-size:cover;
  }
  .login-form .form-row-content>label .iconfont{
    color:#333333;
    font-size:21px !important;
  }
  .login-form .form-row-content .iconfont{
    margin-left:0;
    margin-top:0;
  }
  .login-page .form-row .login-btn button{
    margin-top:15px;
  }
  .login-form .form-row-content{
    /* border-bottom:1px solid #666666; */
    margin-bottom:11px;
  }
  .login-page .form-row input{
    border-bottom:none;
  }
  .login-box-wrapper .login-box {
    padding: 20px 0;
  }

  .login-page .login-form {
    padding: 0 5%;
    margin:15% auto;
  }

  .login-page .form-row .bottom-link a {
    font-size: 12px;
  }
}
@media screen and (max-width:1440px){
    .login-box-wrapper .login-left-image{
      width: 54.7%;
    }
    .login-box-wrapper .login-left-image img{
      width: 100%;
    }
    .login-box-wrapper .login-box {
      margin-right: 30px;
    }
}

@media screen and (max-device-width: 480px) {
  .login-wrapper {
    height: 720px;
    margin-top: 0;
  }

  .login-page {
    width: 100%;
  }
}

@media screen and (max-device-width: 375px) {

  .login-page .form-row .login-icon {
    margin-right: 10px;
  }

  .login-page .bottom-link {
    padding: 0;
  }
  
  .login-page .form-row .bottom-link a {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px){
  .login-wrapper{
    background-position: 70%;
  }
  .login-box-wrapper .login-left-image{
    display:none;
  }
  .login-box-wrapper{
    text-align:center;
  }
  .login-box-wrapper .login-box {
    width: 86%;
    float: initial;
    min-width: auto;
    margin: 0 auto;
    /* height: 44.6em; */
  }
}