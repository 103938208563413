
.forgot-page.container{
  padding: 0;
  margin-top: 100px;
}
.forgot-wrapper {
  background: url('http://lingdaima.dotlinkface.com/login-bg.png') no-repeat center;
  background-size: cover;
  height: 75em;
  position: relative;
  margin-top: 10.5vh;
}

.forgot.index-header {
  background-color: #fff;
}

.forgot-page {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  /* width: 100%; */
  height: 550px;
}

/* .forgot-wrapper .forgot-box-wrapper {
  
} */

.forgot-box-wrapper .forgot-left-image{
  display: inline-block;
}
.forgot-box-wrapper .forgot-box {
  display:inline-block;
  background-color: #fff;
  height: 100%;
  padding: 80px 0;
  width: 30vw;
  max-width: 500px;
  min-width: 370px;
  height: 45vw;
  min-height: 550px;
  max-height: 700px;
  float: right;
}

.forgot-wrapper .forgot-title {
  text-align: center;
  font-size: 18px;
  color: #333;
  font-weight: normal;
}


.forgot-wrapper .forgot-form {
  margin-top: 30px;
  padding: 0 15%;
}

.forgot-wrapper .form-row {
  padding: 0;
  margin-bottom: 15px;
}

.forgot-form .form-row-error {
  height: 40px;
  /* padding-left: 17%; */
  margin-bottom:5px;
}

.forgot-form .form-row-content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  border-bottom: 1px solid #999999;
}

.forgot-wrapper .form-row .signup-icon {
  float: left;
  margin-right: 20px;
}

.forgot-wrapper .form-row input {
  float: left;
  width: 80%;
  height: 33px;
  border: 0;
  outline: none;
  background: rgba(0, 0, 0, 0);
}

#validate {
  width: 50%;
  border: 0;
}

.forgot-wrapper .form-row .validate-btn {
  width: 120px;
	height: 28px;
  background: #ddd;
  border: 0;
}

.forgot-wrapper .form-row .forgot-btn {
  text-align: center;
  margin-top: 40px;
  border: 0;
}

.forgot-wrapper .form-row .forgot-btn button {
  width: 100%;
	height: 40px;
  background-color: #d5a256;
  color: #fff;
  font-size: 16px;
  border: 0;
}
.forgot-cli-log{
  text-align:right;
  margin-top:17px;
}
.forgot-cli-log a{
  color:#d5a256;
  font-size: 14px;
}

.forgot-wrapper .err-msg {
  font-size: 14px;
  color: #666;
  height: 40px;
  background: #f0dfc4;
  line-height: 40px;
  padding-left:18%;
  position: relative;
  display: none;
  border: 1px solid #d5a256;
}
.forgot-wrapper .err-msg:nth-of-type(1):before{
  content: "";
  position: absolute;
  height: 25px;
  width: 25px;
  left: 13px;
  top: 5px;
  background: url('https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/error-tip-icon.png') no-repeat;
  background-size: cover;
}

.forgot-wrapper .form-row .hide {
  display: none;
}

.forgot-wrapper .form-row .code-button {
  width: 120px;
  height: 28px;
  background: #ddd;
  border: 0;
}

.forgot-form .signup-icon .icon {
  font-size: 24px;
  color: #333;
}

.forgot-wrapper .code-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 80%;
  text-align: right;
}

.forgot-wrapper .code-wrapper input {
  width: 50%;
}

.bottom-link:nth-child(3) {
  text-align: right;
}
@media screen and (max-width: 1440px){
  .forgot-box-wrapper .forgot-left-image{
    width: 54.7%;
  }
  .forgot-box-wrapper .forgot-left-image img{
    width: 100%;
  }
  .forgot-box{
    margin-right: 30px
  }
}

@media screen and (max-width: 1240px) {
  .forgot-wrapper .forgot-box-wrapper {
    left: 0;
    right: 0;
    margin: auto;
  }
}
@media screen and (max-width: 768px) {
  .forgot-box-wrapper .forgot-left-image{
    display: none;
  }
  .forgot-box-wrapper .forgot-box {
    width: 86%;
    float: initial;
    height: 44.6em;
    margin-left: 8%;
    min-width: auto;
    min-height: auto;
    height: 426px;
  }
  .forgot-wrapper .err-msg{
    font-size:11px;
  }
  .forgot-page.container{
    margin-top: 50px;
  }
  .forgot-wrapper .err-msg:nth-of-type(1):before{
    height: 20px;
    width: 20px;
    left: 11px;
    top: 5px;
  }
  .forgot-cli-log{
    margin-top:15px;
  }
  .forgot-cli-log a{
    color:#d8a958;
  }
  .forgot-wrapper .form-row{
    width: 73.3%;
    margin: 0 auto;
    margin-bottom: 20px;

  }
  .forgot-form .signup-icon .icon{
    font-size:20px;
  }
}
@media screen and (max-width: 600px) {

  .forgot-form {
    padding: 0 5px;
  }

  .forgot-box-wrapper .forgot-box {
    padding: 20px 0;
  }

  .forgot-wrapper .forgot-form {
    padding: 0 5%;
  }

}

@media screen and (max-device-width: 480px) {
  .forgot-wrapper {
    height: 720px;
    margin-top: 63px;
  }

  .forgot-page.container  {
    width: 100%;
  }

  .forgot-wrapper  .code-wrapper input {
    width: 40%;
  }

}

@media screen and (max-device-width: 375px) {
  .forgot-wrapper .form-row .signup-icon {
    margin-right: 10px;
  }

  .forgot-wrapper .bottom-link {
    padding: 0;
  } 
}