#user-setting {
  color: #4d4a4a;
  padding: 0;
}
/* #user-setting.container-fluid {
  padding: 0 15px;
} */
.my #user-setting .form-row span.icon {
  display: none;
}
.my #user-setting .company-info .phone-industry-btn {
  display: none;
}
.my #user-setting .company-info .dropdown-menu > li > a {
  text-align: left;
}
.my #user-setting .company-info .btn-group{
  float: left;
}
.my #user-setting .company-info .btn-row:after {
  content: '';
  clear: both;
  display: block;
  width: 100%;
  height: 0;
}
.my #user-setting .company-info .phone-company-industry {
  display: none;
}
.my #user-setting .modify .form-row {
  margin-top: 25px;
}
.my #licence label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my .licence-upload label.company-upload-label {
  color: #DBB074;
  font-size: 14px;
  height: 20px;
  cursor: pointer;
}
.my #licence label img {
  max-width: 100%;
  width: auto;
}
.my .user-settings-wrapper #modifyPwd .err-row, .my .user-settings-wrapper #settings .err-row, .my .user-settings-wrapper #companyInfo .err-row {
  height: 0px;
  overflow: hidden;
  margin: 0;
}
#user-setting .avatar-wrapper {
  height: 100px;
  display: flex;
}
#user-setting .avatar-wrapper .avatar-upload {
  flex: 1;
  height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user-settings-wrapper .user-version {
  line-height: 45px;
  font-size: 14px;
}
.user-settings-wrapper .user-version span{
  color: #333;
}
.user-settings-wrapper .user-version span.free-version {
  display: inline-block;
  border-radius: 10px;
  width: 80px;
  height: 36px;
  line-height: 36px;
  background: #d5a256;
  text-align: center;
  color: #fff;
  margin-right: 10px;
}
@media (max-width: 768px) {
  #user-setting {
    margin-left: 0;
  }
  #user-setting .form-row input {
    width: calc(100% - 150px);
  }
  .my .get-code{
    float: right;
    line-height: 44px;
  }
  #user-setting .avatar-wrapper {
    display: block;
  }
  .user-settings-wrapper  .setting-body-wrapper {
    width: 100%  ;
  }
  .my .user-settings-wrapper #modifyPwd .err-row, .my .user-settings-wrapper #settings .err-row, .my .user-settings-wrapper #companyInfo .err-row {    
    border: 0;
    transition: all linear 0.5s;
    margin: 0;
    width: 100%;    
    background: #f0dfc4;
    color: #666;
    padding-left: 5%;
    font-size: 12px;
    vertical-align: middle;
  }
  .my .user-settings-wrapper #modifyPwd .err-row img, .my .user-settings-wrapper #settings .err-row img, .my .user-settings-wrapper #companyInfo .err-row img{
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  .my .user-settings-wrapper #modifyPwd .err-row.active, .my .user-settings-wrapper #settings .err-row.active, .my .user-settings-wrapper #companyInfo .err-row.active {
    height: 29px;
    line-height: 29px;
    border: 1px solid #d5a256!important;
  }
  .my .user-settings-wrapper #modifyPwd, .my .user-settings-wrapper #settings, .my .user-settings-wrapper #companyInfo {
    padding: 0;
    padding-bottom: 20px;
  }
  .my .user-settings-wrapper #modifyPwd.modify .form-row, .my .user-settings-wrapper #settings .form-row, .my .user-settings-wrapper #companyInfo .form-row{
    margin: 0 15px;
  }
  .my #user-setting .user-settings-wrapper .tab-list li a {
    font-size: 12px;
    padding: 4px 0;
  }
  #user-setting.container-fluid, #user-setting.containerr, .user-settings-wrapper.row, .my .nav-wrapper {
    padding: 0;
    margin: 0;
    border: 0;
  }
  #user-setting .user-settings-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  #user-setting .user-settings-wrapper .setting-body-wrapper,#user-setting .user-settings-wrapper .company-info{
    flex: 1;
    overflow-y: auto;
  }
  .my .nav-wrapper ul.tab-list {
    display: flex;
    width: 100%;
    border: 0;
    box-shadow: 0 0 10px #999;
  }
  .my .nav-wrapper ul li {
    flex: 1;
  }
  .my #user-setting .form-row {
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
    margin-top: 0;
    height: 44px;
    position: relative;
  }
  .my #user-setting .form-row>label, .my #user-setting .form-row input, .my #user-setting .form-row > label.avatar-label {
    height: 100%;
    line-height: 44px;
    height: 44px;
    margin:0;    
    text-align: left;
    border: 0;
  }
  .my #user-setting .form-row input {
    width: 65%;
    font-size: 12px;
  } 
  .user-settings-wrapper .user-version {
    font-size: 12px;
  }
  .my .user-settings-wrapper .user-version span.free-version {
    height: 30px;
    line-height: 30px;
  }
  .my #user-setting .user-settings-wrapper .error-tip {
    display: none
  }
  .my #user-setting .form-row label {
    width: auto;
    font-size: 12px;
  }
  .my #user-setting .form-row #licence label {
    width: 100%;
  }
  .my #user-setting .form-row span.icon {
    display: block;
    float: right;
    transform: rotate(270deg);
    line-height: 44px;
    cursor: pointer;
    margin: 0;
  }
  .my #user-setting .user-settings-wrapper .avatar-wrapper {
    height: 44px;
  }
  .my #user-setting .user-settings-wrapper .avatar-upload{
    display: none;
  }
  .my #user-setting .user-settings-wrapper #avatar {
    width: 33px;
    height: 33px;
    float: right;
    margin-top: 5.5px;
  }
  .my #user-setting .confirm-btn {
    margin-left: 0!important;
    width: 100%;
    height: 40px;
    padding: 0;
    font-size: 16px;
  }
  .my #user-setting .btn-row{
    overflow: visible;
  }
  .my .user-settings-wrapper .radios {
    height: 44px;
  }
  .my #user-setting .company-info .form-row:first-child {
    border: 0;
    overflow: visible;
    height: auto;   
  }
  .my #user-setting .company-info .form-row:first-child>label {
    line-height: 44px;
  }
  .my #user-setting .company-info .form-row:first-child .upload-title-mobile {
    display: block;
    height: 44px;
    font-size: 12px;
    color: #747474;
    display: table-cell;
  }  
  .my #user-setting .company-info .form-row:first-child #licence {
    width: 115px;
    height: 80px;
  }
  .my #user-setting .company-info .form-row:first-child .licence-upload {
    display: none;
  }
  .my #user-setting .company-info .long-input {
    width: 50%;
  }
  .my #user-setting .company-info .phone-industry-btn {
    outline: none;
    border: 0; 
    background-color: transparent;
    line-height: 44px;
  }
  .my #user-setting .company-info .btn-group{
    float: right;
  }
  .my #user-setting .company-info .form-row.btn-row {
    overflow: visible;
  }
  .my #user-setting .company-info .form-row.btn-row .dropdown-menu {
    left: -147px;
  }
  .my #user-setting .company-info .form-row.btn-row .industry-btn {
    display: none;
  }
  .my #user-setting .company-info .form-row.btn-row .phone-industry-btn {
    display: block;
  }
  .my #user-setting .company-info .phone-company-industry {
    display: block;
    float: left;
    text-indent: 10px;
    line-height: 44px;
  }
  .my #user-setting .modify .form-row input {
    background: transparent;
  }
  .my #user-setting .modify .form-row {
    margin-top: 0;
  }
}
.my div.upload-title-mobile {
  display: none;
}
.my {
  /* overflow: hidden; */
  position: relative;
}
.my-content {
  overflow: hidden;
  position: relative;
}

.user-settings-wrapper {
  margin: 42px 70px;
  height: 88%;
  border: 1px solid #d2d2d2;
  overflow: hidden;
  background: #fff;
  padding: 40px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}

.user-settings-wrapper .tab-list {
  list-style: none;
  border-bottom: solid 1px #c6c6c6;
  overflow: hidden;
  padding: 0;
  background: #fff;
}

.user-settings-wrapper .tab-list li {
  float: left;
  border-bottom: 2px solid;
  border-color: rgba(200,200,200,0);
}

.user-settings-wrapper .tab-list li.activeclass {
  border-color: #d5a256;
}

.user-settings-wrapper .nav-wrapper .tab-list li.activeclass a {
  color: #333;
}
.user-settings-wrapper .nav-wrapper .tab-list li a {
  display: block;
  padding: 11px 21px;
  color: #999;
  font-size: 16px;
}
.user-settings-wrapper .nav-wrapper .tab-list li.active a{
  font-weight: 500;
}


.user-settings-wrapper .setting-body-wrapper, .user-settings-wrapper .company-info, .user-settings-wrapper .modify {
  position: relative;
  overflow-y: auto;
  width: 100%!important;
  flex: 1;
  padding-bottom: 60px;
}
.user-settings-wrapper .nav-wrapper {
  position: relative;
  z-index: 2;
 
}
.user-settings-wrapper .setting-body-wrapper .avatar-wrapper {
  vertical-align: middle;
}
.my .get-code, .my .disable-send {
  color: #d5a256;
  margin-left: 20px;
  cursor: pointer;
}
.my .disable-send {
  cursor: not-allowed;
}


#user-setting .form-row {
  margin-top: 25px;
}

.user-settings-wrapper .error-tip {
  color: #666;
  display: inline-block;
  font-size: 14px;
  vertical-align: middle;
  margin-left: 15px;
  background: #f0dfc4;
  border: 1px solid #d5a256;
  padding: 5px;
  font-size: 0;
}
.user-settings-wrapper .error-tip img{
  width: 25px;
  height: 25px;
  margin-right:10px;
}
.user-settings-wrapper .error-tip span{
  font-size: 14px;
}
#modifyPwd .form-row {
  margin-top: 0;
}

#user-setting .form-row > label {
  line-height: 45px;
  float: left;
  margin: 0 22px 0 0;
  font-weight: normal;
  width: 90px;
  text-align: right;
  font-size: 14px;
  color: #999;
}

#companyInfo .form-row > label {
  width: 120px;
}

#modifyPwd .form-row > label {
  width: 90px;
}

#user-setting .form-row input {
  height: 45px;
  width: 300px;
  border: solid 1px #d2d2d2;
  margin-right: 5px;
  padding-left: 9px;
  font-size: 16px;
}

@media (max-width : 500px){ 
  .user-settings-wrapper .form-row .avatar-upload, .user-settings-wrapper .licence-upload{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
}

#user-setting .form-row > label.avatar-label{
  line-height: 88px;
}

.user-settings-wrapper #avatar {
  display: inline-block;
  background: linear-gradient(#eee, #eee);
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background-image: url('http://lingdaima.dotlinkface.com/avatar-default.png') no-repeat;
  background-size: cover;
  margin-right: 15px;
}
.user-settings-wrapper #avatar div.avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.user-settings-wrapper #avatar img {
  max-width: 100%;
  height: auto;
  border-radius: 0;
}


 .avatar-upload, .licence-upload {
  display: inline-block;
}

.avatar-upload .upload{
  height: 35px;
}

.user-settings-wrapper .upload label {
  display: inline-block;
  background-color: #fff;
  font-size: 14px;
  color: #DBB074;
  width: 80px;
  height: 28px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

 #licence {
  display: inline-block;
  width: 230px;
  height: 155px;  
  margin-right: 15px;
  background: linear-gradient(#DBE0DF, #DBE0DF);
  background-size: 58px 44px;
  background-origin: content-box;
}
#licence  .licence-img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
} 
#licence  .licence-img img{
  width: 100%;
  height: auto;
  overflow: hidden;
} 
#licence label {
  cursor: pointer;
}

#licence img {
  width: 100%;
  height: auto;
  display: block;
}

#avatar-btn, #upload-licence {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

#avatar .default-img {
  /* width: 100%; */
  height: 100%;
  display: block;
}

.my .upload-title {
  font-size: 14px;
  color: #a1a1a1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.my .company-info .dropdown-menu {
  height: 275px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.my .company-info .dropdown-menu::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.my .company-info .dropdown-menu > li > a {
  padding: 0;
  text-indent: 21px;
  line-height: 39px;
}
.my .company-info .dropdown-menu > li:hover a{
  color: #d5a256;
}
.my .company-info .dropdown-menu::-webkit-scrollbar-thumb {
  background: #d5a256;
}
.my .company-info  .dropdown-menu::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eee;
}
.user-settings-wrapper .radios{
  height: 56px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.user-settings-wrapper .radios span {
  margin-right: 66px;
}
.my .sex {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 1px #d6a45a;
  margin-right: 8px;
  cursor: pointer;
}

.user-settings-wrapper  .checked {
  background: url(http://lingdaima.dotlinkface.com/choose-right.png) center no-repeat;
  border: 0;
}

.btn-row {
  text-align: center;
}
.confirm-btn {
  color: #f5f5f5;
  background-color: #333;
  border: 0;
  width: 180px;
  height: 50px;
  float: left;
  margin-left: 112px;
  font-size: 22px;
  margin-top: 30px;
}
.confirm-btn:hover{
  background-color: #d5a256;
}
 .industry-btn {
  background: #fff;
  border: 1px solid #d2d2d2;
  width: 300px;
  height: 45px;
  text-align: right;
}
.industry-btn span {
  color: #333;
  margin-left: 20px;
  font-size: 16px;  
}

.phone-number{
  margin-right: 15px;
}

.phone-info .change{
    color: #4d4a4a;
    font-size: 14px;
}

 .industry-btn span{
  float: left;
}

.phone-info .phone-replace{
 color: #bb8869;
 font-size: 14px;
 margin-left: 15px;
}


/* companyInfo.js start */

/* #user-setting .form-row > label.licence-label{
  line-height: 160px;
} */
#companyInfo {
  vertical-align: middle;
}
#companyInfo .form-row input.long-input{
  width: 538px;
}
#companyInfo  .licence-wrapper{
  padding-bottom: 10px;
}

/*companyInfo.js end  */


/*弹窗 s */

 #UserSettingTipModle .modal-dialog{
     width: 420px;
 }

 .modal-content .replace-List{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
 }

 .replace-List .replace-item{
  margin: 30px 0 40px;
 }

 .replace-List .input-div-1{
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    align-items: center;
 }

.input-div-1 label{
  margin-right: 7px;
  margin-bottom: 0;
}

 .input-div-1 label i{
   font-size: 20px;
 }

 .input-div-1 span{
   font-size: 14px;
   color: #333333;
 }

 .input-div-1 input{
  border: none;
  margin-left: 6px;
  width: calc(300px - 58px);
  height: 25px;
  outline: none;
  height: 50px;
  font-size: 14px;
 }

 .input-div-1 .Verification-code{
  width: calc(300px - 113px);
 }

 .replace-List .getcode{
  width: 80px;
	height: 28px;
  background-color: #f2f2f2;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
 }

 .replace-List .submit-btn{
  width: 120px;
  height: 34px;
  background-color: #bb8869;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  margin: 20px auto 0;
  color: #ffffff;
  letter-spacing: 2px;
 }

 .replace-List .error-tip{
  height: 20px;
  margin-bottom: 10px;
 }

/*弹窗 e */
