.testinterface{
    position: fixed;
    /* right: 20px; */
    right: 0;
    top: 55vh;
    z-index: 999;
}
.testinterface #online-contract{
    width: 90px;
    height: 90px;
    color: #fff;
    background: #d5a256;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.testinterface #qrCode{
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #d5a256;
    border-bottom: none;
    border-right: none;
}
.testinterface #qrCode img{
    width: 100%;
}
#qrText{
    width: 90px;
    padding: 10px;
    color: #999;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d5a256;
    border-top: none;
    border-right: none;
}
.testinterface-content{
        width: 90px;
        height: 90px;
        /* background-color: #bb8869; */
        background-color: #dddddd;
        /* border-radius: 16px; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
}

.testinterface-img{
    width: 22px;
    height: 22px;
    margin-bottom: 13px;
    display: block;
}

.testinterface-content .testinterface-title{
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
    line-height: 0px;
    color: #000000;
    /* writing-mode: tb-rl; */
}
@media screen and (max-width:768px){
    .testinterface #online-contract{
        width:60px;
        height: 60px;
    }
    .testinterface #qrCode{
        width:60px;
        height: 60px;
    }
    .testinterface-content{
        width: 60px;
        height: 60px;
    }
    .testinterface {
        transform: scale(0.8) translateX(20%);
    }
    #qrText{
        width: 60px;
        padding: 5px;
        color: #999;
        font-size: 12px;
        text-align: center;
        background-color: #fff;
    }
}