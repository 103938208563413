.models-layer{
    background-color: rgba(0,0,0,0.4);
}


.models-layer .modal-layer {

    background-color: #ffffff;
    box-shadow: 0px 6px 24px 4px rgba(139, 137, 137, 0.28);
}

.models-layer .modal-header {
    width: 100%;
    height: 38px;
    background-color: #e5e5e5;
    padding: 0 20px 0 23px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.models-layer .modal-header-left {
    height: 13px;
    line-height: 13px;
    font-size: 16px;
    color: #3d3c3c;
}

.models-layer .modal-header-right {
    width: 12px;
    height: 12px;
    line-height: 12px;
    cursor: pointer;
}

.models-layer .modal-header-right span {
    font-size: 16px;
}

.models-layer .modal-content {
    min-height: 221px;
    width: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 0 #fff !important;
    border-radius: 0;
    border: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
}




.models-layer a:hover {
    text-decoration: none;
}

/* if is theme-1  s*/

.models-layer.modal[data-theme='theme-1'] .modal-dialog {
    width: 360px;
}



.models-laye .save-tip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.models-layer .save-img {
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin-bottom: 6px;
}

.models-layer .save-img span {
    line-height: 50px;
    font-size:50px;
    color:  #bb8869;
}

.models-layer .save-text {
    font-size: 20px;
    text-align: center;
    color: #333333;
    margin-top: 20px;
}


.models-layer .tip-success-btn div{
    width: 120px;
    height: 34px;
    background-color: #efe1d9;
    text-align: center;
    line-height: 34px;
    cursor: pointer;
    font-size: 14px;
    color: #666666;
    margin-top: 32px;
   
}


/*  if is theme-1  e */

