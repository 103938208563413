#nav-back {
    position: static!important;
    left: 0!important;
    top: 0!important;
    margin: 0!important;
    height: 10%;
    background: #eee;
}

.company {
    background-color: #333333;
    border-radius: 0;
}
#nav-back .user-center-logo{
    width: 17.8;
    height: 10%;
    position: absolute;
    left: 0;
    top: 0;
    max-width: 330px;
    box-shadow: 5px 0px 5px #d1d1d1;
    display: flex;
    align-items: center;
    justify-content: center;
}
#nav-back .user-right-concat {
    position: absolute;
    right: 73px;
    line-height: 10vh;
    top: 0;
    vertical-align: middle;
    font-size: 0;
    color: #666;
}
#nav-back .user-right-concat span {
    font-size: 14px;
    margin-left: 10px;
}
#nav-back .user-center-logo img {
    width: 100%;
    
}
#nav-back .user-center-logo a {
    display: block;
    width: 55%;
}
#nav-back .user-center-logo span{
    margin-left: 15px;
    font-size: 1vw;
    color: #666;
}
/* .designer {
    background-color: #d6e9f0; 
} */
#nav-back .navbar-header {
    display: none;
}
#nav-back .back-header {
    margin-top: 20px;
    /* padding: 0 30px; */
    margin-left: 56px;
    width: calc( 100% - 112px );
}
#nav-back .user-center-logo {
    width: 17.8%;
    max-width: 330px;
    box-shadow: 5px 0px 5px #d1d1d1;
}
@media screen and (max-width: 768px) {
    #nav-back {
        height: 63px!important;
    }
    #nav-back .navbar-header {
        display: block;
        
    }
    #nav-back .back-header {
        padding: 0 10px;
        margin-left: 0;
        width: 100%;
        margin-top: 10px;
    }
    #nav-back .user-center-logo, #nav-back .user-right-concat {
        display: none;
    }
}


#nav-back .navbar-right  img {
    background: #eee;
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

#nav-back .navbar-right span{
    vertical-align: middle;
}

#nav-back .back-logo {
    display: inline-block;
    margin-right: 9px;
}
#user-setting-menu{
    display: none;
}

@media (max-width : 768px){
    #nav-back {
        vertical-align: middle;
    }
    #nav-back .back-logo {
        width: 120px;
    }
    #nav-back .navbar-brand span {
        font-size: 16px;
        color: #666;
    }

    #user-setting-menu{
        float: right;
        width: 60px;
        height: 50px;
        display: block;
    }
    #user-setting-menu .user-logo{
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-top: 10px;
        margin-left: -5px;
    }
    #user-setting-menu .caret{
        margin-top: 5px;
    }
}

#nav-back .user-center {
    font-size: 16px;
    color: #555555;
}
#nav-back .navbar-right a {
    font-size: 16px!important;
    color: #ffffff;
}

#nav-back .nav-backbar-brand img {
    height: 33px!important;
}

#nav-back .navbar-right .free-img {
    color: #ed8aa2;
}

#nav-back .navbar-Link {
    color: #555555;
    margin-left: 9px;
    margin-right: 5px;
}

#nav .free-img {
    color: #555;
}

#nav-back .exit {
    color: #555;
    margin-left: 29px;
}
#nav-back #droppdown-box {
    float: right;
    width: 90px;
    position: relative;    
}
#nav-back .back-header .dropdown-menu {
    min-width: 90px;
    max-width: 90px;
    text-align: center;
    margin-right: 19px;
    border-radius: 0;
    border:0;
}
#nav-back .back-header .dropdown-menu>li {
    /* line-height: 30px; */
    /* margin: 0 20px; */
    border: 0;
}
#nav-back .back-header .dropdown-menu>li:hover {
    background: #d5a256;
}
#nav-back .back-header .dropdown-menu>li:first-child:hover {
    background: #fff;
}
#nav-back .back-header .dropdown-menu>li:first-child a{
    color: #333;
}
#nav-back .back-header .dropdown-menu>li:first-child:hover a{
    background: #fff;
    color: #333;
    border-color: #eee;
}
#nav-back .back-header .dropdown-menu>li:hover a, #nav-back .back-header .dropdown-menu>li:hover a span{
    color: #fff;
    background: #d5a256;
    border-color: #d5a256;
}
#nav-back .back-header .dropdown-menu>li> a {
    color: #999;    
    display: inline-block;
    padding: 0;
    width: 100%;
    text-align: left;
    text-indent: 5px;
}
#nav-back .back-header .dropdown-menu>li> a >span{
    width: 88%;
    display: inline-block;
    border-bottom: 1px solid #eee;
    padding: 6px 0;
}
#nav-back .back-header .dropdown-menu>li> a.link-active {
    background: #d5a256;
    color: #fff;
}
#nav-back .back-header .dropdown-menu>li> a.link-active  span{
    border-color: #d5a256;
}
#nav-back .back-header .dropdown-menu>li:last-child> a{
    border: 0;
}
