.erweima-modal-content {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1051;
}
.site-list{
    padding-bottom: 50px;
    height: calc( 100% - 100px );
    position: relative;
}
.site-list .webTip{
    position: absolute;
    bottom: 10px;
    display: block;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
    color: red;
    width: 100%;
}
.erweima-modal-content .erweima-modal-box {
    width: 28vw;
    overflow: hidden;
}
.erweima-modal-content .erweima-modal-box .erweima-modal-box-title {
    padding: 0 1vw;
    height: 4vw;
    background: #d5a256;
}
.erweima-modal-content .erweima-modal-box .erweima-modal-box-title span {
    font-size: 1.3vw;
    line-height: 4vw;
    color: #fff;
}
.erweima-modal-content .erweima-modal-box .erweima-modal-box-title span.iconfont1 {
    color: #fff;
    position: relative;
    display: inline-block;
    font-size: 2vw!important;
    cursor: pointer;
    float: right;
} 
.iconfont2 {
    position: relative !important;
    display: inline-block !important;
    font-size: 2vw!important;
    cursor: pointer;
    margin-left:10px !important;
    color:#d5a256;
} 
.erweima-modal-content .erweima-modal-box .erweima-img {
    text-align: center;
}
.erweima-modal-content .erweima-modal-box .erweima-img canvas {
    width: 12vw!important;
    margin: 1vw 0;
}
.erweima-modal-content .erweima-modal-box .erweima-modal-box-content {
    background: #fff;
    padding: 1vw;
}
.erweima-modal-content .erweima-modal-box .erweima-modal-box-content p {
    text-align: center;
    vertical-align: middle;
    font-size: 0;
}
.erweima-modal-content .erweima-modal-box .erweima-modal-box-content p span{
    margin-left: 10px;
    font-size: 0.8vw;
}
@media (min-width: 768px) {
    .site-list-container.container{
        /* margin-left: 17.2%; */
        /* width: 1400px; */
        /* background-color:#f9f9f9; */
    }
}

@media (max-width: 1600px) and (min-width: 1300px){
    .content{
        width: 1100px !important;
    }
}

@media (max-width: 768px) {

    
    .site-list {
      margin-left: 0;
      position: relative;
    }

    /* .site-list-container.container{
        /* margin-left: 15px; 
    } */
    .site-list-wrap .col-md-12{
        padding:0;
    }
   

    .site-list #info-modal{
        width: 100%;
        background: none;
    }
    .erweima-modal-content .erweima-modal-box {
        width: 70%;
    }
    .erweima-modal-content .erweima-modal-box .erweima-modal-box-title,.erweima-modal-content .erweima-modal-box .erweima-modal-box-title span {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        padding: 0 3%;
        margin: 0;
    }
    .erweima-modal-content .erweima-modal-box .erweima-img canvas {
        width: 50%!important;
        margin: 10px 0;
    }
  }

  .site-list .add-site {
    margin-top: 30px;
    text-align: right;
    padding-right: 130px !important;
    
}

@media (max-width : 768px){
    .site-list .add-site .col-md-12{
        display: flex;
        justify-content: center;
    }
}


.site-list .add-site-button {
    font-size: 14px;
    color: #ffffff;
    width: 160px;
    height: 45px;
    letter-spacing: 1px;
    font-size: 14px;
    display: inline-block;
    line-height: 45px;
    text-align: center;
    background:#333333;
    position: relative;
    /* left:-1170px; */
}
.site-list .add-site-button:hover{
    background-color:#d5a256;
}

.site-list #my-site-list {
    padding: 40px 52px 32px 53px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    
}
.site-list #my-site-list  .add-site {
    padding: 0;
}
.site-list-wrap .col-md-12 {
    padding: 0;
}
.rua-change-pic{
    display:block !important;
    position: absolute !important;
    left:0 !important;
    right:0 !important;
    top:0 !important;
    bottom:0 !important;
    margin: auto !important;
}

@media (max-width : 768px){
    .site-list #my-site-list {
        padding: 15px 0 32px;
    }
    .site-list .templ{
        border-bottom:solid 1px #f2f2f2;
        margin-bottom: 80px;
        margin-left:20px;
    }

    .site-list .templ .bd a{
        width: 100%  !important;
    }

    .visit.des-row div{
        width: 50%;
        float: left;
    }
}

.site-list .templ{
    width: 25%;
    margin-bottom:30px;
    position:relative
}

.site-list .templ:hover{
    box-shadow: 0 0 10px #999;
}

.site-list .templ .des{
    float:none;
    width:100%;
    overflow: hidden;
    /* height: 13vw; */
    padding-bottom:20px;
    background-color:#f9f9f9;
}

.templ:hover .des{
    background:#fff;
}
.templ:hover .des .operate-btns .black-btn{
    background:#d5a256;
    color:#fff;
}
/* .templ:hover .des .operate-btns .black-btn{
    background:transparent;
    color:#d5a256;
    border:1px solid #d5a256;
} */
.templ:hover .des .operate-btns .iconfont{
    display:inline-block;
}
.site-list .templ .bd,.site-list .templ .bd a{
    width:100%;
}

.site-list .templ  .site-img{
    width: 378px;
    height: 275px;
    display: block;
    overflow: hidden;
}

.site-list .templ  .site-img img{
    width: 378px;
	height: auto;
}

.site-list .templ .bd{
    /* height: 16vw; */
    float:none;
    padding:0;
    font-size:0;
    position: relative;
    border-bottom:1px solid transparent;
    overflow: hidden;
}

.site-list .templ .bd a{
    display: block;
    /* height: 412px; */
    overflow: hidden;
}

.site-list .templ .bd .bd-img{
    width:100%;
    
}

.templ .bd .height247{
    height: 247px;
}

.site-list .des {
    padding-bottom: 76px;
}

.site-list .des .site-status .col-md-6{
    padding-left:0;
}
.site-list .des .site-status{
    margin-bottom:13px;
}

.site-list .des .des-row {
    font-size: 0.9vw;
    color: #898888;
    margin-bottom: 0.7vw;
}

.site-list .des .des-title {
    color: #333333;
    position: relative;
    font-size: 0;
    vertical-align: middle;
    line-height: 3;
}
.site-list .des .des-title i {
    cursor: pointer;
    width: 1vw;
    height: 1.5vw;
    margin: 0 5px;
    margin-top: 1vw;
    display: inline-block;
    background: url('http://lingdaima.dotlinkface.com/serviceupgrade/phone-icon.png') no-repeat;
    background-size: contain;
    float: right;
}
.site-list .des .des-title i.phone:hover {
    background-image: url('http://lingdaima.dotlinkface.com/serviceupgrade/phone-active-icon.png')
}
.site-list .des .des-title i.xiaochengxu {
    width: 1.4vw;
    background: url('http://lingdaima.dotlinkface.com/serviceupgrade/xiaochengxu-icon.png') no-repeat;
    background-size: contain;
}
.site-list .des .des-title i.xiaochengxu:hover {
    background-image: url('http://lingdaima.dotlinkface.com/serviceupgrade/xiaochengxu-active-icon.png')
}
.site-list .des .des-title span {
    font-size: 1.3vw;   
}
.site-list .des .des-title span.des-border {
    position: absolute;
    width: 20px;
    border: 1px solid #d5a256;
    bottom: 0;
    left: 0px;
}
.site-list .des .des-title span.des-class {
    float: right;
    color: #d5a256;
    font-size: 1vw;
}
.site-list .des .desc-tips {
    color: #000;
    margin-left: 18px;
}

.site-list .des .operate-btns .black-btn {
    width: 27%;
    margin-right: 19px;
    text-align: center;
    padding: 2% 8%;
    color: #666666;
    cursor: pointer;
    font-size: 0.9vw;
    background-color:transparent;
    /* border:1px solid #666666; */
}
.site-list .des .operate-btns .black-btn{
    background:#333;
    color:white;
}

.site-list  .iconfont{
    font-size: 1.2vw !important;
    margin-left:170px;
    display: none;
    color:#d5a256;
    position: absolute;
    right:0;
    bottom:0;
}
.templ .rua{
    background:url("https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/test-erweima.png") 1px -1px no-repeat;
    height: 4vw;
    width: 4vw;
    position: absolute;
    right:0;
    top:0;
    z-index:5;
    background-size: 100% 100%;
    display: none;
}
.templ:hover .rua {
    display: block;
}
@media (max-width:768px){
    .site-list .des .operate-btns .black-btn {
        width: 87px;
        height: 25px;
        margin-top: 13px;
    }
    #root .web .my .my-body .site-list {
        overflow: visible;
        width: 100%;
    }
    .my .site-list .container .site-list-wrap {
        overflow: initial;
        overflow-y: initial!important;
    }
    .my .site-list .container {
        overflow-y: scroll
    }
    #root .web .my .my-body{
        background: #fff;
    }
    .site-list .add-site-button{
        width:345px;
        background-color:#d5a256;
        color:#fff;
        border:1px solid transparent;
        font-size:15px;
        left:0;
    }
    .site-list .templ{
        width:100%;
        height:342px;
        margin-bottom:15px;
        border-bottom:none;
        box-shadow: 0 0 10px #999;
        position: relative;
    }
    .site-list .templ:nth-of-type(odd){
        margin-left:0;
    }
    
    .site-list .templ .bd,.site-list .templ .bd a{
        height:205px;
    }
    .site-list .templ .des {
        padding:15px;
        padding-top: 0;
        background-color:#fff;
        height:136px;
    }
    .site-list .templ .des .des-title{
        font-size:13px;
        margin:0 0 4px 0;
    }
    .site-list .des .des-row,.site-list .templ .des .last-time{
        font-size:12px;
    }
    .site-list .des .des-row{
        margin-bottom:5px;
    }
    .site-list .des .last-time{
        margin-bottom: 10px !important;
    }
    /* .site-list .des .operate-btns .black-btn:nth-of-type(1){
        width:102px;
        height:30px;
        border:1px solid #d5a256;
        background-color:#d5a256;
        color:#fff;
    } */
    .site-list .des .operate-btns .black-btn{
        width:102px;
        height:30px;
        border:1px solid #d5a256;
        background-color:transparent;
        font-size:12px;
        line-height:30px;
        margin-right:11px;
        color:#d5a256;
    }
    .site-list .iconfont{
        display:inline-block;
        font-size:17px !important;
        /* position: relative; */
    }
    .rua{
        display: none;
    }
    .templ:hover .bd .site-erweima{
        display:none;
    }
    .templ:hover .bd{
        padding-left:0 !important;
        padding-top:0 !important;
    }
    .site-list .templ:hover .url-289{
        display:block;
    }
    .templ:hover .rua {
        display: none;
    }
    .site-list .des .des-title i, .site-list .des .des-title i.xiaochengxu {
        width: 15px;
        height: 15px;
        margin-top: 13px;
    }
}

.rua2{
    background:url("https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/test-computer.png") 0px 0px;
    height: 4vw;
    width: 4vw;
    position: absolute;
    right:0;
    top:0;
    z-index:5;
    background-size: 100% 100%;
}
.templ .bd .site-erweima{
    display:none;
    text-align: center;
    background: #fff;
}
.templ .bd .site-erweima .site-erweima-img{
    width: 100%
}
.templ .bd .site-erweima .site-erweima-img img{
    width: 15vw
}
.templ .bd .site-erweima .site-erweima-img canvas{
    width: 12vw!important;
    margin-top: 1vw;
    height: auto;
}
.templ .bd .site-erweima .erweima-friend-msg {
    position: absolute;
    bottom: 10px;
    vertical-align: middle;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: #333;
    width: 100%;
    font-size: 0.9vw;
}
.templ .bd .site-erweima .erweima-friend-msg img {
    margin-right: 10px;
    width: 1.5vw;
} 
.rua-popup{
    width:50%;
    height:50%;
    margin:10% auto;
    text-align:center;
}
.rua-popup span{
    font-size:15px;
    color:#999999;
}
.rua-popup button{
    text-align:center;
    background:#333333;
    color:#fff;
    border:none;
    outline:none;
    width: 140px;
    height: 40px;
    font-size: 14px;
    margin-top:35%;
}
.rua-popup button:hover{
    background:#d5a256;
}

.templ:hover .bd{
    box-sizing: border-box;
    background-color:#fff;
    border-bottom:1px solid #ccc;
}

.site-list .des .last-time{
    margin-bottom: 2vw;
}
.site-list .des-domain a{
    color: #898888;
}

.site-list .show-line {
    border: solid 1px #eeeeee;
    height: 1px;
    margin-bottom: 81px;
}

.hide {
    display: none;
}

.site-list .site-capture {
    width: 140px;
    transition:all 0.3s ease-in-out;
}
.site-list .templ .bd .url-289{
    overflow:hidden;
}
.site-list .templ .bd .url-289:hover .site-capture{
    transform: scale(1.1);
}


/* 提示弹窗 */

.tip-success.tip-del {
    width: 360px;
}

.tip-del .tip-body{
    width: 100%;
    background-color: #ffffff;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}



.tip-success.tip-del  .tip-success-img{
    margin-bottom: 19px;
}

.tip-success.tip-del  .tip-success-img span{
    font-size: 50px;
    color: #efe1d9;
    line-height: 50px;
}

.tip-success.tip-del  .tip-success-txt{
    margin-bottom: 32px;
}

.tip-del .tip-success-txt h3{
    font-size: 20px;
    margin: 0;
}

.tip-del .tip-success-btn{
    width: 250px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tip-success-btn div{
    width: 90px;
    height: 26px;
    background-color: #efe1d9;
    text-align: center;
    line-height: 26px;
    cursor: pointer;
    font-size: 14px;
    color: #666666;
}

.flex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
#listPage .headerComponent .logo{
    height: 113px;
}
.isexpired{
    background: #666 !important;
    color: #fff !important;
}
.isexpired:hover{
    background: #666 !important;
    color: #fff !important;
}