
.onetoone {
    width: 100%;
    height: auto;
    background-color: #f9f9f9;
    margin-top: 10.5vh;
}

.onetoone .container-fluid {
    overflow: hidden;
}
.carousel1 {
    margin-top: 365px;
}
.carousel1 .carousel-cell {
    width: 50%;
    height: 473px;
    margin-right: 10px;
    background: #8C8;
    border-radius: 5px;
    counter-increment: gallery-cell;
  }
.carousel1 .carousel-cell:before {
    display: block;
    text-align: center;
    content: counter(gallery-cell);
    line-height: 200px;
    font-size: 80px;
    color: white;
  }
.onetoone .index-header {
    box-shadow: 0px 4px 3px 1px rgba(0, 0, 0, .2);
    border: 0;
}

.onetoone hr {
    margin-top: none;
    border-color: #e4e4e4;
}

.onetoone .swiper-container.btm-banner {
    width: 100%;
    overflow: visible;
    position: absolute;
    cursor: pointer;
    bottom: 45px;
}
.onetoone .swiper-container.btm-banner .swiper-slide .btm-hover-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .2);
    opacity: 0;
    transition: all ease-in-out 0.5s;
}

.onetoone .swiper-container.btm-banner .swiper-slide.swiper-slide-active:hover  .btm-hover-content {
    opacity: 1;
}
.onetoone .swiper-container.btm-banner .swiper-slide.swiper-slide-active:hover img{
    transform: scale(1.1);
}
.onetoone .swiper-container.btm-banner .swiper-slide .btm-hover-content a{
    display: block;
    width: 100%;
    height: 100%;
}
.onetoone .swiper-container.btm-banner .swiper-slide.swiper-slide-active{
    opacity: 1;
}
.onetoone .swiper-container.btm-banner .swiper-slide {
    text-align: center;
    font-size: 18px;
    width: 43.6%;
    opacity: 0.7;
    transition: all ease-in-out 0.5s;
    position: relative;
    overflow: hidden;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.onetoone .swiper-container.btm-banner .swiper-slide img{
    width: 100%;
    height: auto;
    max-height: 100%;
    transition: all ease-in-out 0.5s;
}
.onetoone .swiper-container.btm-banner .swiper-pagination {
    display: none;
}
.onetoone .onetoone-examples .bg-content {
    font-size: 16px;    
    color: #333;
    line-height: 32px;
}
.onetoone .onetoone-examples .bg-title {
    font-size: 22px;
    line-height: 54px;
    margin: 0;
    color: #333;
}
.onetoone .examples-content > div{
    position: relative;
    overflow: hidden;
    margin-top: 65px;
}
.onetoone .swiper-container-designer .user-img {
    position: relative;
}
.onetoone .swiper-container-designer .user-img .sex-icon {
    position: absolute;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    /* background-image: url('http://lingdaima.dotlinkface.com/imgonetoone/onetoone-girl-icon.png'); */
    box-shadow: 0px 0px 20px #dedede;
    left: 76px;
    bottom: 10px;
}
.onetoone .swiper-container-designer .user-img-content{
    height: 114px;
    width: 114px;
    border-radius: 67px;
    border: 1px solid #e7e7e7;
    overflow: hidden;
}
.onetoone .swiper-container-designer .user-img-content img {
    width: 100%
}
.onetoone .swiper-container-designer .user-name {
    color: #333;
    line-height: 60px;
    font-size: 16px;
    font-weight: 600;
}
.onetoone .swiper-container-designer .info-list {
    display: flex;
    flex-direction: row;
    color: #999;
    font-size: 14px;
}
.onetoone .swiper-container-designer .swiper-button-next, .onetoone .swiper-container-designer .swiper-button-prev{
    background: none;
    text-align: center;
}
.onetoone .swiper-container-designer .swiper-button-next span.icon {
    transform: rotate(-90deg);
}
.onetoone .swiper-container-designer span.icon {
    margin-left: -10px;
    margin-top: -10px;
    font-size: 44px;
    display: inline-block;
    color: #bbb; 
}
.onetoone .swiper-container-designer span.icon:hover {
    color: #d5a256;
}

.onetoone .swiper-container-designer .swiper-button-prev span.icon {    
    transform: rotate(90deg);      
}
.onetoone .swiper-container-designer .swiper-slide {
    width: 33.3%;
    padding: 0 6%;
}
.onetoone .arrows {
    position: absolute;
}

.onetoone .arrow {
    width: 5%;
    margin-right: 0;
}

.onetoone .swiper-container-designer .info-list p:first-child{
    width: 58px;
}
.onetoone .swiper-container-designer .info-list p:last-child{
    flex: 1;
}
.onetoone h1 {
    font-size: 34px;
}

.my-advantage {
    background: #eee;
}
.onetoone .designer-team{
    padding-bottom: 100px;
}
.onetoone-banner img {
    width: 100%;
    height: auto;
}
.onetoone .txtcenter {
    margin-top: 90px;
    text-align: center;
    color: #333;
    font-size: 36px;
}
.onetoone .txt-en{
    width: 35%;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #bbb;
    vertical-align: middle;
    position: relative;
}

.onetoone .txt-en span {
    display: inline-block;
    border: 1px solid #e5e5e5;
    width: 30%;
    font-size: 12px;
    line-height: 26px;
}
.onetoone .txt-en span i {
    position: absolute;
    width: 7px;
    height: 7px;
    background: #e5e5e5;
    right: -1px;
    border-radius: 100%;
    top: -4px;
}

.onetoone .txt-en span.txt-left {
    position: absolute;
    left: 0px;
    top: 50%;
}
.onetoone .txt-en span.txt-right {
    position: absolute;
    right: 0px;
    top: 50%;
}
.onetoone .txt-en span.txt-right i {
    left: -1px;
    border-radius: 100%;
}
.onetoone .txt-en .txt-center {
    border: 0;
}
.my-advantage-list {
    margin-top: 120px;
    margin-bottom: 160px;
}

.my-advantage-list .row > div {
    height: 200px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.2%;
    transition: all 0.3s ease-in-out;
    position: relative;
}
.my-advantage-list .hover-content{    
    position: absolute;
    height: 100%;
    transition: all ease-in-out 0.6s;
    background: #d5a256;
    width: 100%;
    z-index: 1;
    cursor: pointer;
    display: none;
    flex-direction: column;
    align-items: center;
    color: #fff; 
    text-align: center;
}
.my-advantage-list .row > div .hover-content h1{font-size: 48px;}
.my-advantage-list .row > div .hover-content .title {color: #fff;margin-bottom: 10px;}
.my-advantage-list .row > div .hover-content .contet-right {color: #fff;}
.my-advantage-list .row > div:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
}
.my-advantage-list .row > div:hover .hover-content {
    display: flex;
    height: 150%;
}


.onetoone .first-boder {
    width: 20px;
    height: 2px;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 20px;
}

.my-advantage-list .title {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
}

.my-advantage-list .conter {
    margin-top: 50px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #666666;
    display: flex;
    justify-content: space-around;
}

.my-advantage-list .content-left {
    width: calc(100% - 60px)
}

.onetoone .contet-right {
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 10px;
    display: inline-block;
}

.onetoone .phone-advantage-list {
    display: none;
}
.my-advantage-list .row>div:nth-child(1) .contet-right, .onetoone .phone-advantage-list .content:nth-child(1) .contet-right {
    background-image: url('http://lingdaima.dotlinkface.com/imgonetoone/onetoone-fuwu.png')
}

.my-advantage-list .row>div:nth-child(2) .contet-right, .onetoone .phone-advantage-list .content:nth-child(2) .contet-right {
    background-image: url('http://lingdaima.dotlinkface.com/imgonetoone/onetoone-design.png')
}

.my-advantage-list .row>div:nth-child(3) .contet-right, .onetoone .phone-advantage-list .content:nth-child(3) .contet-right {
    background-image: url('http://lingdaima.dotlinkface.com/imgonetoone/onetoone-gongneng.png')
}

.my-advantage-list .row>div:nth-child(4) .contet-right, .onetoone .phone-advantage-list .content:nth-child(4) .contet-right {
    background-image: url('http://lingdaima.dotlinkface.com/imgonetoone/onetoone-weihu.png')
}
.my-advantage-list .row > div:nth-child(1):hover .contet-right {
    background-image: url('http://lingdaima.dotlinkface.com/imgonetoone/onetoone-fuwu-active.png')
}
.my-advantage-list .row > div:nth-child(2):hover .contet-right {
    background-image: url('http://lingdaima.dotlinkface.com/imgonetoone/onetoone-design-active.png')
}
.my-advantage-list .row > div:nth-child(3):hover .contet-right {
    background-image: url('http://lingdaima.dotlinkface.com/imgonetoone/onetoone-gongneng-active.png')
}
.my-advantage-list .row > div:nth-child(4):hover .contet-right {
    background-image: url('http://lingdaima.dotlinkface.com/imgonetoone/onetoone-weihu-active.png')
}
.Compared-content {
    margin-top: 43px;
}

.compared-content-top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 118px;
}

.compared-content-top>div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.compared-content-top div:nth-child(2) {
    width: 40.6%;
    background: #535353;
    color: #fff;
}
.compared-content-top div:nth-child(3) {
    flex: 1;
    background: #f9f9f9;
    color: #888888;
    font-size: 20px;
}

.compared-content-top div:nth-child(1) {
    width: 20.8%;
    background: #f9f9f9;
}

.compared-content-top>div span {
    margin-left: 25px;
    font-size: 20px;
}

.desginer-compared {
    padding-bottom: 60px;
    background: #f9f9f9;
}

.compared-content-list {
    margin-bottom: 40px;
}

.compared-content-top .vs {
    font-family: SourceHanSansCN-Bold;
    font-size: 52px;
    line-height: 0px;
    letter-spacing: 0px;
    color: #a5a5a5;
}

.compared-content-item {
    display: flex;
    flex: 1;
    height: 100px;
}

.compared-content-item>div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.compared-content-item .item-1{
    width: 40.6%;
    background: #666;
    border-bottom: 1px solid #535353;    
}
.compared-content-item:last-child .item-1{
    border-bottom: 0;    
}
.compared-content-item .item-2{    
    width:20.8%;
}
.compared-content-item:nth-child(odd)  {
    background: #eee;
}
.compared-content-item:nth-child(even) {
    background: #f9f9f9;
}
.compared-content-item .item-3{
    flex: 1;
}

.compared-content-item span {
    color: #999;
    margin-left: 10px;
    font-size: 24px;
    text-align: left;
}
.compared-content-item .item-2 img{
    margin-left: 5%;
}
.compared-content-item .item-2 span {
    margin-left: 0;
    text-align: left;
    width: 60%;
    text-indent: 10px;
}
.compared-content-item .item-1 span {
    color: #fff;
}
.onetoone .process {
    width: 100%;
    overflow: hidden;
    position: relative;
}
.onetoone .process-phone {
    display: none;
}
.onetoone .process img {
    width: 100%;
    height: auto;
}
.onetoone .process > div.row {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;top: -50px;
    z-index: 1;
}

.onetoone-examples {
    padding-bottom: 60px;   
    background: #999;
    height: 950px;
    position: relative;
}
.onetoone-examples .white-bg {
    position: absolute;
    width: 37.5%;
    padding: 2% 3.75%;
    height: 80%;
    bottom: 0;
    background: #fff;
    left: 28.3%;
}

.onetoone-examples .txtcenter {
    color: #fff;
}
.onetoone .onetoone-examples .phone-pointer {
    display: none;
}
.onetoone .onetoone-examples .web-pointer {
    display: flex;
    justify-content: center;
    margin-left: calc(76.3% - 118px);
    line-height: 40px;
    width: 118px;
    color: #fff;
    position: absolute;
    bottom: 15px;
}
.onetoone .onetoone-examples .web-pointer li{
    margin: 0 6px;
}
.onetoone .onetoone-examples .web-pointer li span {
    color: #fff;    
}
.onetoone .onetoone-examples .web-pointer li span.icon {
    margin:0;
    font-size: 20px!important;
    display: block;
}
.desginer-compared {
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 40px;
}
.onetoone #btm-prev:hover, .onetoone #btm-next:hover {
    color: #d5a256
}
@media screen and (max-width: 768px) {
    .onetoone{
        margin-top: 60px;
    }
    .onetoone .txtcenter{
        margin-top: 24px;
    }
    .onetoone .txt-en {
        width: 65%;
    }
    .onetoone .txt-en .txt-center {
        width: 100%;
        transform: scale(0.9)
    }
    .onetoone .swiper-container-designer .swiper-slide {
        padding: 0 14%;
    }

    .onetoone .examples-content .row{
        margin: 0;
    }
    .onetoone .designer-team .container, .onetoone .designer-team, .onetoone .my-advantage{
        padding: 0;
        padding-bottom: 10px;       
    }
    /* .onetoone .my-advantage .container {
        padding-left: 5px;
        padding-right: 5px;
    } */
    .onetoone .swiper-container-designer .user-img-content {
        width: 67px;
        height: 67px;
    }
    .onetoone .swiper-container-designer .user-img .sex-icon {
        width: 18px;
        height: 18px;
        background-size: 50%;
        left: 52px;
    }
    .onetoone .my-advantage-list {
        display: none;
    }
    .onetoone .phone-advantage-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 15px;
        
    }
    .onetoone .examples-content > div {
        margin-top: 30px;
    }
    /* .onetoone .swiper-container-designer img {
        
    } */
    .onetoone .swiper-container-designer .user-name {
        line-height: 50px;
        font-size: 13px;
    }
    .onetoone .phone-advantage-list .content {
        height: 100%;
        transition: all ease-in-out 0.6s;
        background: #fff;
        border: 1px solid #e5e5e5;
        width: 48%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #bbb; 
        text-align: center;
        margin-bottom: 10px;
    }
    .onetoone .phone-advantage-list .content .first-boder {
        background: #d6a44f;
        margin: 0;
    }
    .onetoone .phone-advantage-list .content .title {
        color: #333;
        line-height: 30px;
    }
    .onetoone .phone-advantage-list .content h1 {
        margin: 5px 0;
    }
    .onetoone .phone-advantage-list .content-left {
        margin-bottom: 10px;
    }
    .onetoone .compared-content-list {
        margin-left: 0;
        margin-right: 0;
    }
    .onetoone .compared-content-top div{
        font-size: 16px;
        flex-direction: column;
    }
    .onetoone .compared-content-top div img {
        width: 38px;
    }
    .onetoone .compared-content-top {
        height: 59px;
    }
    .onetoone .compared-content-top div span{
        margin: 0;
        font-size: 13px;
    }
    .onetoone .compared-content-item .item-1,
    .onetoone .compared-content-item .item-3 {
        justify-content: center;
        flex-direction: column;
    }
    .onetoone .compared-content-item {
        height: 60px;
    }
    .onetoone .compared-content-item img{
        margin: 0;
    }
    .onetoone .compared-content-item span {
        justify-content: center;
        font-size: 12px;
        /* margin-top: 10px; */
        align-items: center;
        margin-left: 0;
        text-align: center;
        width: 90%;
        text-indent: 0;
    }
    .onetoone .compared-content-item div.item-2 {
        flex-direction: column;
    }
    .onetoone .desginer-compared .container {
        padding: 0;
    }
    .onetoone .compared-content-item img {
        width: 15%;
        height: auto;
    }
    .onetoone .my-advantage-list .row>div {
        padding: 10%;
    }
    .onetoone .my-advantage-list .contet-right {
        margin-top: -40px;
    }
    .onetoone .process>img {
        display: none;
    }
    .onetoone .process>div.row {
        position: static;
        height: 100px;

    }
    .onetoone .process .iconfont {
        background: #d5a256;
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
        color: #fff;
        line-height: 24px;
        margin: 0;
    }
    .onetoone .center-bolder {
        width: 2px;
        height: 100%;
        background: #e5e5e5;
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%) scaleX(0.5);
    }
    .onetoone .process-phone .process-phone-item {
        text-align: center;
        width: 50%;
        height: 100%;
        position: relative;
    }
    .onetoone .process-phone .flickity-viewport {
        height: 100%!important;
    }
    .onetoone .process-phone .flickity-viewport .flickity-slider {
        height: 100%;
    }
    .onetoone .process-phone-item .process-step {
        font-size: 60px;
        color: #f2f2f2;
        position: absolute;
        left: 15%;
        top: 27%;
        line-height: 0;
    }
    .onetoone .process-phone-item img{
        width: 60px;
        height: auto;
        margin-top: 73px;
        margin-left: 15%;
        transform: translate(0);
        z-index: 2;
    }
    .onetoone .process {
        height: 270px;
        width: 100%;
        display: flex;
        padding: 0;
        flex-direction: column;
        margin: 0;
         background: url(http://lingdaima.dotlinkface.com/imgonetoone/onetoone-process-phone-bg.png); 
        background-size: 100% 100%;
        background-repeat: no-repeat; 
    }
    .onetoone .process-phone {
        display: flex;
        width: 100%;
        border-top: 1px solid #e5e5e5;
        flex: 1;
        position: relative;
    }
    .onetoone .process-phone-carousel {
        width: 92%;
        border-right: 1px solid #e5e5e5;
        border-left: 1px solid #e5e5e5;
        height: 100%;
        margin: 0 auto;
    }
    .onetoone .txt-en{width: 80%;}
    .onetoone .txtcenter {
        font-size: 16px;
    }
    .onetoone .deatil-block {
        align-items: center;
        margin-top: 10px;
    }
    .onetoone .deatil-block .access-url {
        margin-top: 0;
        margin-bottom: 20px;
    }
    .desginer-compared, .onetoone-examples {
        background-size: 290px auto;
        margin-top: 0;
        padding-bottom: 0;
    }
    .onetoone .my-advantage-list,
    .onetoone .Compared-content, {
        margin-top: 25px;
    }
    .onetoone .onetoone-examples {
        height: 480px;
    }
    .onetoone .onetoone-examples .white-bg {
        width: 86%;
        left: 5.1%;
        height: 78%;
    }
    .onetoone .onetoone-examples .bg-content{
        font-size: 12px;
        line-height: 16px;
        color: #999;
    }
    .onetoone .onetoone-examples .white-bg .bg-title {
        font-size: 16px;
        line-height: 40px;
    }
    .onetoone .swiper-container.btm-banner .swiper-slide {
        width: 85%;
    }
    .onetoone .onetoone-examples .btm-banner {
        bottom: 25px;
    }
    .onetoone .onetoone-examples .web-pointer{
        display: none;
    }
    .onetoone .swiper-container.btm-banner .swiper-pagination {
        display: block;
        bottom: -20px;
    }
    .onetoone .swiper-container.btm-banner .swiper-pagination .swiper-pagination-bullet-active {
        background: #d5a256;
    }
    
}
@media screen and (max-width: 1440px) {
    .onetoone-examples .white-bg {
        height: 73%
    }
}