.copyright {
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    padding-top:90px;
}

.copyright-1 .copyright-header h3{
    margin-top:103px;
    max-width: 1200px;
}

.copyright-1{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    padding: 0 34px 46px;
    margin-bottom: 37px;
    background-color: #f9f9f9;
}

.copyright-header .row{
    display: flex;
    justify-content: center;
    align-items: center;
}

.copyright-1 h3{
    font-size: 24px;
    line-height: 1;
    color: #262626;
    margin: 33px 0 32px;
    padding-bottom: 18px;
    text-align: center;
    /* border-bottom: solid 1px #898888; */
}

.copyright-list{
    
    margin: auto;
  
}
.copyright-list .row{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}


.copyright-list img{
    width: 300px;
    height: auto;
    display: block;
    margin-top:50px;
}


@media screen and (max-width: 768px) {
    .copyright {
        display: flex;
        flex-direction: column;
        
    }
    .copyright-list img{
        /* width: 120%;
        height: 100%;
        display: block; */
        margin-top:10px;
    }
    .copyright-1 .copyright-header h3{
        display:none;
    }
    .copyright-1{
        margin-bottom:86px;
        margin-top:63px;
    }
    .copyright-list .row{
        display: flex;
        justify-content: center;
    }
    
    
}