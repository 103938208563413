#prompt-modal .modal-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#prompt-modal .modal-content {
  width: 380px;
	background-color: #fefefe;
	box-shadow: 0px 4px 23px 3px 
    rgba(99, 98, 98, 0.28);
  border-radius: 0;
  border: 0;
}

.prompt-header {
  height: 50px;
}

.prompt-header .close {
  margin-right: 15px;
  opacity: 1;
}

.prompt-header .close span {
  font-size: 20px;
  color: #fff;
  line-height: 50px;
}

.header-company {
  background: #d5a256;
}

.header-designer {
  background: #88dcfb;
}

.prompt-header-title {
  float: left;
  line-height: 50px;
  margin-left: 12px;
  color: #fff;
  font-size: 18px;
}

.prompt-body {
  position: relative;
  width: 100%;
  height: 192px;
}

.prompt-body-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.prompt-body-text {
  margin-top: 12px;
  margin-bottom: 37px;
}

.prompt-body-btn button {
  width: 160px;
  height: 40px;
  font-size: 16px;
}
.prompt-body-btn button:hover {
  background: #d5a256;
}
